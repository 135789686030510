import React, { useState, useEffect } from "react";
import axios from "axios";

const Counter = ({
  onMeterFactoryNumber,
  onMeterFactoryYearChange,
  onMeterFactoryWater,
  onSaveRegistrySelection,
}) => {
  const accessToken = localStorage.getItem("accessToken");

  /*----Номер в реестре фиф */
  const [numberRegistryOpen, setNumberRegistryOpen] = useState(false);
  const [numberRegistryValue, setNumberRegistryValue] = useState("");
  const [metersData, setMetersData] = useState([]);
  const [suggestedMetersData, setSuggestedMetersData] = useState([]);

  const [selectedMeterNameSi, setSelectedMeterNameSi] = useState("");

  /*----Получаем данные от бэкэнда----- */
  const fetchMeters = async () => {
    try {
      const response = await axios.get(
        "https://app.metriva.ru/api/staff/meters",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("полученные данные:", response.data);
      setMetersData(response.data.data);
    } catch (error) {
      console.error("Error fetching meters:", error);
    }
  };

  useEffect(() => {
    fetchMeters();
  }, []);

  const handleNumberRegistryChange = (event) => {
    const value = event.target.value;
    setNumberRegistryValue(value);

    const suggestedData = metersData.filter(
      (meter) =>
        meter.number_registry &&
        meter.number_registry.includes(value) &&
        meter.status === "verified"
    );
    setSuggestedMetersData(suggestedData);
  };

  const handleNumberRegistrySelection = (selectedRegistry) => {
    const selectedMeter = metersData.find(
      (meter) => meter.number_registry === selectedRegistry
    );

    setNumberRegistryValue(selectedRegistry);

    handleNameSelection(selectedMeter?.name_si);
    console.log("Выбранный number_registry:", selectedRegistry);
    handleNumberRegistryOpen();
    onSaveRegistrySelection(selectedRegistry, selectedMeter?.id);
  };

  const handleNameSelection = (selectedNameSi) => {
    setSelectedMeterNameSi(selectedNameSi);
  };

  const handleNumberRegistryOpen = () => {
    setNumberRegistryOpen(!numberRegistryOpen);
  };

  useEffect(() => {
    if (!numberRegistryValue) {
      setSuggestedMetersData([]);
    }
  }, [numberRegistryValue]);

  /*--------po-up Год---- */
  const [year, setYear] = useState(false);
  const [selectedYear, setSelectedYear] = useState(0);
  const list3 = Array.from({ length: 24 }, (_, index) =>
    (2000 + index).toString()
  );
  const handleYearChange = (index) => {
    const selectedYear = list3[index];
    setSelectedYear(index);
    setYear(false);
    onMeterFactoryYearChange(selectedYear);
  };

  const yearName = list3[selectedYear];

  /*-----select Тип Воды--- */
  const [selectedWater, setSelectedWater] = useState("cold");
  const handleWaterSelection = (selectedWater) => {
    setSelectedWater(selectedWater);
    onMeterFactoryWater(selectedWater);
  };

  /*------Заводской номер---- */
  const [meterFactoryNumber, setMeterFactoryNumber] = useState("");
  const handleMeterFactoryNumberChange = (event) => {
    setMeterFactoryNumber(event.target.value);
    onMeterFactoryNumber(meterFactoryNumber);
  };

  return (
    <div className="protocol__counter">
      <div className="protocol__counter-text">
        <p>Поверяемый счётчик</p>
      </div>
      <div className="protocol__counter-items">
        <div className="protocol__counter-paragraph">
          <div className="protocol__counter-paragraph1">
            <p>Номер в реестре (ФИФ) :</p>
            <div className="protocol__counter-paragraph-items">
              <div className="search" onClick={handleNumberRegistryOpen}>
                <input
                  value={numberRegistryValue}
                  onChange={handleNumberRegistryChange}
                />
              </div>
            </div>
          </div>

          {suggestedMetersData.length > 0 && numberRegistryOpen && (
            <div className="fif_down">
              {suggestedMetersData.map((meter) => (
                <p
                  key={meter.id}
                  onClick={() =>
                    handleNumberRegistrySelection(meter.number_registry)
                  }
                >
                  {meter.number_registry}
                </p>
              ))}
            </div>
          )}
        </div>

        <div className="protocol__counter-paragraph">
          <p>Наименование счётчика :</p>
          <div className="protocol__counter-paragraph-items">
            <div className="search">
              <p>{selectedMeterNameSi}</p>
            </div>
          </div>
        </div>

        <div className="protocol__counter-paragraph-year">
          <p>Год выпуска :</p>
          <div className="protocol__counter-paragraph-items">
            <div className="search-year" onClick={() => setYear(!year)}>
              <p>{yearName}</p>
              <span className="fas fa-angle-down"></span>
            </div>

            {year && (
              <div className="fif_down">
                {list3.map((name, index) => (
                  <p
                    key={index}
                    onClick={() => handleYearChange(index)}
                    className={selectedYear === index ? "active-1" : ""}
                  >
                    {name}
                  </p>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="protocol__counter-paragraph-water">
          <p>Измеряемая вода:</p>
          <div className="protocol__counter-paragraph-items-water">
            {selectedWater !== "hot" && (
              <div
                className={`search-water-cold ${
                  selectedWater === "hot" ? "selected" : ""
                }`}
                onClick={() => handleWaterSelection("hot")}
              >
                <p>Холодная</p>
              </div>
            )}
            {selectedWater !== "cold" && (
              <div
                className={`search-water-hot ${
                  selectedWater === "cold" ? "selected" : ""
                }`}
                onClick={() => handleWaterSelection("cold")}
              >
                <p>Горячая</p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="protocol__counter-paragraph-items-bottom">
        <p>Заводской номер :</p>
        <input
          className="protocol__counter-paragraph-items-bottom-input"
          placeholder="Напишите заводской номер"
          value={meterFactoryNumber}
          onChange={handleMeterFactoryNumberChange}
        />
      </div>
    </div>
  );
};

export default Counter;
