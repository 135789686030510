import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import {
  FaPlus,
  FaEdit,
  FaTrash,
  FaFilePdf,
  FaCheck,
  FaTimes,
  FaFileCsv,
} from "react-icons/fa";
import { saveAs } from "file-saver";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Protocol = () => {
  const accessToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const location = useLocation();

  const [apiData, setApiData] = useState([]);
  const [protocolData, setProtocolData] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const [userData, setUserData] = useState({
    role: role,
  });

  /*------ pop-up Компания ---------*/
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [companyData, setCompanyData] = useState([]);
  const [companyOpen, setCompanyOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const handleCompanyClick = async (company) => {
    setSelectedCompany(company);
    setSelectedCompanyId(company ? company.id : null);
    setCompanyOpen(false);

    // Сохраняем выбранную компанию в localStorage
    localStorage.setItem("selectedCompanyId", company ? company.id : "");

    // Сбросить текущую страницу на первую
    const newPage = 1;

    let queryParams = `/admin/protocols?page=${newPage}`;
    if (company) {
      queryParams += `&company_id=${company.id}`;
    }

    navigate(queryParams);
  };

  const fetchCompanyData = async () => {
    try {
      const companyApiUrl = "https://app.metriva.ru/api/staff/companies/list";
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      };

      const companyResponse = await axios.get(companyApiUrl, { headers });

      setCompanyData(companyResponse.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  // Вызывайте fetchCompanyData внутри useEffect
  useEffect(() => {
    fetchCompanyData();
  }, []);

  /*------Дата ОТ/ДО--- */
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const formatDate = (dateString) => {
    const date = moment.utc(dateString, "YYYY-MM-DDTHH:mm:ss");
    return date.format("YYYY-MM-DD");
  };

  /*------ pop-up Метролог ---------*/
  const [selectedUser, setSelectedUser] = useState(null);
  const [filteredProtocols, setFilteredProtocols] = useState([]);
  const [selectedMetrolog, setSelectedMetrolog] = useState("Все метрологи");
  const [listMetrolog, setListMetrolog] = useState([]);
  const [metrologOpen, setMetrologOpen] = useState(false);

  /*-----Подтвержденные/не подтвержденные--- */
  const [confirm, setConfirm] = useState(false);
  const [selectedConfirm, setSelectedConfirm] = useState(0);
  const listConfirm = ["Все протоколы", "Подтвержденные", "Не подтвержденные"];
  const onClickListConfirm = (i) => {
    setSelectedConfirm(i);
    setConfirm(false);
  };

  const SelectedConfirmName = listConfirm[selectedConfirm];

  /*------Получаем данные от бэкэнд (список протоколов)------ */
  const fetchData = async (queryParams, page = 1) => {
    try {
      const apiUrl = `https://app.metriva.ru/api/staff/protocols?page=${page}`;
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      };

      const response = await axios.get(apiUrl, {
        headers,
        params: {
          ...queryParams,
          company_id: selectedCompanyId,
        },
      });

      setFilteredProtocols(response.data.data);
      setPagination({
        current_page: response.data.meta.current_page,
        last_page: response.data.meta.last_page,
      });
    } catch (error) {
      console.error(error);
      setFilteredProtocols([]);
    }
  };

  useEffect(() => {
    const queryParams = {
      created_by: selectedUser === null ? null : selectedUser,
      verification_date_from: null,
      verification_date_to: null,
      status: null,
    };

    fetchData(queryParams, currentPage);
    localStorage.setItem("selectedUser", JSON.stringify(selectedUser));
  }, [selectedUser, selectedCompanyId, currentPage]);

  /*------Получаем данные от бэкэнд в pop-up Пользователя */
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const apiUrl = "https://app.metriva.ru/api/staff/users/list";
        const headers = {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        };
        const response = await axios.get(apiUrl, { headers });
        const users = response.data.data;
        const metrologists = users.filter(
          (user) => user.role === "metrologist" || "brigadier"
        );
        setListMetrolog(metrologists);
        setSelectedMetrolog("Все метрологи");
      } catch (error) {
        console.error(error);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
      } catch (error) {
        console.error(error);
      }
    };

    fetchUsers();
  }, [filteredProtocols]);

  const handleShowButtonClick = () => {
    const formattedStartDate = selectedStartDate
      ? moment(selectedStartDate).format("YYYY-MM-DD")
      : null;
    const formattedEndDate = selectedEndDate
      ? moment(selectedEndDate).format("YYYY-MM-DD")
      : null;
    const statusFilter =
      SelectedConfirmName === "Подтвержденные"
        ? "checked"
        : SelectedConfirmName === "Не подтвержденные"
        ? "ready"
        : null;

    const queryParams = {
      created_by: selectedUser === null ? null : selectedUser,
      verification_date_from: formattedStartDate,
      verification_date_to: formattedEndDate,
      status: statusFilter,
    };

    fetchData(queryParams);
    localStorage.setItem("selectedUser", JSON.stringify(selectedUser));
  };

  const [pagination, setPagination] = useState({
    current_page: 1,
    last_page: 1,
  });

  const handlePageClick = (page) => {
    let queryParams = `?page=${page}`;

    if (selectedCompanyId) {
      queryParams += `&company_id=${selectedCompanyId}`;
    }

    // Fetch data for the clicked page
    fetchData(queryParams, page);

    // Update the URL
    console.log("Navigating to:", `/admin/protocols${queryParams}`);
    navigate(`/admin/protocols${queryParams}`);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (!params.has("page")) {
      // Если параметр 'page' отсутствует, добавляем его со значением 1
      params.set("page", "1");
      navigate(`/admin/protocols?${params.toString()}`);
    }
    const page = parseInt(params.get("page"), 10) || 1;

    const queryParams = {
      created_by: selectedUser === null ? null : selectedUser,
      verification_date_from: null,
      verification_date_to: null,
      status: null,
    };

    fetchData(queryParams, page);
    setCurrentPage(page);
    localStorage.setItem("selectedUser", JSON.stringify(selectedUser));
  }, [location.search, selectedUser, selectedCompanyId, navigate]);

  /*----Delete---- */
  const [deleteError, setDeleteError] = useState("");
  const handleDeleteConfirmation = async () => {
    try {
      await axios.delete(
        `https://app.metriva.ru/api/staff/protocols/${protocolData.id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
        }
      );

      setApiData(apiData.filter((item) => item.id !== protocolData.id));

      setShowConfirmation(false);
    } catch (error) {
      console.error(error);
      if (
        error.response?.data?.message ===
        "Вы не создавали этот протокол. Вы не можете его удалить."
      ) {
        setDeleteError(
          "Вы не создавали этот протокол. Вы не можете его удалить."
        );
      } else {
        setDeleteError(
          "Вы не создавали этот протокол. Вы не можете его удалить."
        );
      }
    }
  };

  /*--------Кнопки Смотреть/Ред/Удалить------- */

  const handleCasesClick = () => {
    navigate("/admin/protocols/create");
  };

  const handleViewClick = (protocolsId) => {
    const selectedData = filteredProtocols.find(
      (item) => item.id === protocolsId
    );
    navigate(`/admin/protocols/${protocolsId}`, {
      state: { data: selectedData },
    });
  };

  const handleViewEditClick = (id) => {
    const selectedData = apiData.find((item) => item.id === id);
    navigate(`/admin/protocols/${id}/edit`, {
      state: { usersData: selectedData },
    });
  };

  const handleDeleteClick = (item) => {
    setProtocolData(item);
    setShowConfirmation(true);
    setDeleteError("");
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
  };

  /*-----onClick PDF----- */
  const handleDownloadPdf = async (protocolId) => {
    try {
      const apiUrl = `https://app.metriva.ru/api/protocols/${protocolId}/pdf`;
      const headers = {
        Authorization: "Bearer 67|Cid09Jbit3KauCQTA0gdhMqwe6lgXCaYhvmZ1pdF",
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/pdf",
      };

      const response = await axios.get(apiUrl, {
        headers,
        responseType: "blob",
      });

      // Извлечение имени файла из заголовков ответа

      const protocol = apiData.find((item) => item.id === protocolId);
      const protocolCreatedAt = moment(protocol?.created_at).format(
        "YYYY-MM-DD"
      );
      const fileName = `Protocol_${protocolId}_${protocolCreatedAt}.pdf`;

      // Сохранение файла на компьютере пользователя
      saveAs(response.data, fileName);
    } catch (error) {
      console.error(error);
    }
  };

  /*-----onClick CSV----- */
  const handleDownloadCsv = async () => {
    try {
      const apiUrl = "https://app.metriva.ru/api/protocols/csv";
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      };

      const response = await axios.get(apiUrl, {
        headers,
        responseType: "blob",
      });

      const contentDisposition = response.headers["content-disposition"];
      const filename = contentDisposition
        ? contentDisposition.split(";")[1].split("=")[1]
        : "html.csv";

      const currentDate = new Date()
        .toLocaleString("ru-RU", {
          timeZone: "Europe/Moscow",
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        })
        .replace(/[.:]/g, "-");

      // Replace the filename with the current date
      const updatedFilename = filename.replace("html", currentDate);

      saveAs(new Blob([response.data]), updatedFilename);
    } catch (error) {
      console.error(error);
    }
  };

  /*------статус подвержден----- */
  const getIconForStatusConfirm = (statusConfirm) => {
    if (statusConfirm === "ready") {
      return <FaTimes className="icon" style={{ color: "red" }} />;
    } else {
      return <FaCheck className="icon" style={{ color: "green" }} />;
    }
  };

  /*------статус аршин----- */
  const getIconForStatusArshin = (statusArshin) => {
    if (statusArshin === "not-sent") {
      return <FaTimes className="icon" style={{ color: "red" }} />;
    } else {
      return <FaCheck className="icon" style={{ color: "green" }} />;
    }
  };

  /*------статус фса----- */
  const getIconForStatusFsa = (statusFsa) => {
    if (statusFsa === "not-sent") {
      return <FaTimes className="icon" style={{ color: "red" }} />;
    } else {
      return <FaCheck className="icon" style={{ color: "green" }} />;
    }
  };

  useEffect(() => {
    setFilteredProtocols([]);
  }, [role]);

  useEffect(() => {
    const storedRole = localStorage.getItem("role");
    if (role !== storedRole) {
      localStorage.setItem("role", storedRole);
      setUserData((prevUserData) => ({
        ...prevUserData,
        role: storedRole,
      }));
    }
  }, [role]);

  return (
    <div>
      <div className="header">
        <button onClick={handleCasesClick} className="create-button">
          <FaPlus className="create-icon" />
          Добавить
        </button>

        {role === "super" && (
          <div className="selected_meter">
            <div className="protocol_categories-box1">
              <div
                className="protocol_categories_top"
                onClick={() => setCompanyOpen(!companyOpen)}
              >
                <p>{selectedCompany ? selectedCompany.name : "Все компании"}</p>
                <span className="fas fa-angle-down"></span>
              </div>
              {companyOpen && (
                <div className="protocol_categories_bottom">
                  <p onClick={() => handleCompanyClick(null)}>Все компании</p>
                  {companyData.map((company) => (
                    <p
                      key={company.id}
                      onClick={() => handleCompanyClick(company)}
                    >
                      {company.name}
                    </p>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="main">
        <main className="main-container">
          <div className="protocol_categories">
            <div className="protocol_categories-box">
              <div className="protocol_calendar">
                <span className="far fa-calendar-alt"></span>
                <DatePicker
                  className="protocol_calendar-input"
                  dateFormat="yyyy-MM-dd"
                  placeholderText="От"
                  selected={selectedStartDate}
                  onChange={(date) => setSelectedStartDate(date)}
                  maxDate={new Date()}
                />
              </div>
              <div className="protocol_calendar">
                <span className="far fa-calendar-alt"></span>
                <DatePicker
                  className="protocol_calendar-input"
                  dateFormat="yyyy-MM-dd"
                  placeholderText="До"
                  selected={selectedEndDate}
                  onChange={(date) => setSelectedEndDate(date)}
                  maxDate={new Date()}
                />
              </div>

              <div className="selected_meter">
                <div className="protocol_categories-box1">
                  <div
                    className="protocol_categories_top"
                    onClick={() => setMetrologOpen(!metrologOpen)}
                  >
                    <p>
                      {selectedUser
                        ? listMetrolog.find((user) => user.id === selectedUser)
                            ?.name
                        : "Все метрологи"}
                    </p>
                    <span className="fas fa-angle-down"></span>
                  </div>

                  {metrologOpen && (
                    <div className="protocol_categories_bottom">
                      <p
                        onClick={() => {
                          setSelectedUser(null);
                          setSelectedMetrolog("Все метрологи");
                          setMetrologOpen(false);
                        }}
                      >
                        Все метрологи
                      </p>
                      {listMetrolog.map((user) => (
                        <p
                          key={user.id}
                          onClick={() => {
                            setSelectedUser(user.id);
                            setSelectedMetrolog(user.name);
                            setMetrologOpen(false);
                          }}
                        >
                          {user.name}
                        </p>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <div className="selected_meter">
                <div className="protocol_categories-box1">
                  <div
                    className="protocol_categories_top"
                    onClick={() => setConfirm(!confirm)}
                  >
                    {SelectedConfirmName}
                    <span className="fas fa-angle-down"></span>
                  </div>

                  {confirm && (
                    <div className="protocol_categories_bottom">
                      {listConfirm.map((name, i) => (
                        <p key={i} onClick={() => onClickListConfirm(i)}>
                          {name}
                        </p>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="protocol_categories-btn">
                <button onClick={handleShowButtonClick}>Показать</button>
              </div>

              <div className="excel" onClick={handleDownloadCsv}>
                <FaFileCsv className="excel-icon" />
              </div>
            </div>
          </div>

          <div className="attend">
            <div className="attendance">
              <div className="attendance-list">
                <table className="table">
                  <thead>
                    <tr>
                      <th>№</th>
                      <th>Дата поверки</th>
                      <th>Метролог</th>
                      <th>Заказчик</th>
                      <th>СИ</th>
                      <th>Результат</th>
                      {role === "super" && <th>Компания</th>}
                      <th className="th">Проверен</th>
                      <th className="th">Статус Аршин</th>
                      <th className="th">Статус ФСА</th>
                      <th className="th">Просм.</th>
                      <th className="th">Ред.</th>
                      <th className="th">PDF</th>
                      <th className="th">Удалить</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredProtocols.map((item, index) => (
                      <tr key={index}>
                        <td>{item.id}</td>
                        <td>{formatDate(item.verification_date)}</td>
                        <td>{item.createdBy.name}</td>
                        <td>{item.owner_name}</td>
                        <td>
                          {item.caseRelation
                            ? item.caseRelation.inner_name
                            : "N/A"}
                        </td>
                        <td>
                          {item.total_accepted ? "Пригоден" : "Не пригоден"}
                        </td>
                        {role === "super" && (
                          <td>{item.createdBy?.company?.name}</td>
                        )}

                        <td className="th">
                          {getIconForStatusConfirm(item.status)}
                        </td>
                        <td className="th">
                          {getIconForStatusArshin(item.status_arshin)}
                        </td>
                        <td className="th">
                          {getIconForStatusFsa(item.status_fsa)}
                        </td>
                        <td
                          onClick={() => handleViewClick(item.id)}
                          style={{ cursor: "pointer" }}
                        >
                          <span className="fas fa-eye download-icon"></span>
                        </td>
                        <td
                          onClick={() => handleViewEditClick(item.id)}
                          style={{ cursor: "pointer" }}
                        >
                          <FaEdit className="download-icon" />
                        </td>
                        <td onClick={() => handleDownloadPdf(item.id)}>
                          <FaFilePdf className="download-icon" />
                        </td>
                        <td onClick={() => handleDeleteClick(item)}>
                          <FaTrash className="download-icon" />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="pagination">
                  {Array.from(
                    { length: pagination.last_page },
                    (_, i) => i + 1
                  ).map((page) => (
                    <button
                      key={page}
                      className={
                        pagination.current_page === page
                          ? "active_paginate"
                          : ""
                      }
                      onClick={() => handlePageClick(page)}
                    >
                      {page}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      {/* Окно подтверждения удаления */}
      {showConfirmation && (
        <div className="confirmation-modal">
          <div className="confirmation-modal__content">
            <h3>Вы уверены, что хотите удалить?</h3>
            <div className="confirmation-modal__buttons">
              <button onClick={handleDeleteConfirmation}>Удалить</button>
              <button onClick={handleCancelDelete}>Отмена</button>
            </div>
            {deleteError && <p className="error-message">{deleteError}</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default Protocol;
