import React, { useState, useEffect } from "react";
import { replace } from "lodash";

const Testimony = ({ onSaveTestimony }) => {
  const [start, setStart] = useState("");
  const [finish, setFinish] = useState("");
  const [reference, setReference] = useState((0.01).toFixed(3));
  const [volumeDifference, setVolumeDifference] = useState("0.000");
  const [formattedRelativeError, setFormattedRelativeError] = useState("0.00");
  const [blockColor, setBlockColor] = useState("3px solid #ee6969");
  const [start1, setStart1] = useState("");
  const [finish1, setFinish1] = useState("");
  const [reference1, setReference1] = useState((0.02).toFixed(3));
  const [volumeDifference1, setVolumeDifference1] = useState("0.000");
  const [formattedRelativeError1, setFormattedRelativeError1] =
    useState("0.00");
  const [blockColor1, setBlockColor1] = useState("3px solid #ee6969");
  const [start2, setStart2] = useState("");
  const [finish2, setFinish2] = useState("");
  const [consumption, setConsumption] = useState("");
  const [reference2, setReference2] = useState((0.1).toFixed(3));
  const [volumeDifference2, setVolumeDifference2] = useState("0.000");
  const [formattedRelativeError2, setFormattedRelativeError2] =
    useState("0.00");
  const [blockColor2, setBlockColor2] = useState("3px solid #ee6969");

  const handleSaveTestimony = () => {
    console.log("volumeDifference из testimony", volumeDifference);
    console.log("formattedRelativeError из testimony", formattedRelativeError);
    console.log("start из testimony", start);
    console.log("blockColor из testimony", blockColor);

    onSaveTestimony({
      start,
      finish,
      start1,
      finish1,
      start2,
      finish2,
      consumption,
      reference,
      volumeDifference,
      formattedRelativeError,
      blockColor,
      reference1,
      volumeDifference1,
      formattedRelativeError1,
      blockColor1,
      reference2,
      volumeDifference2,
      formattedRelativeError2,
      blockColor2,
    });
  };

  /*----Данные -1 ---  */
  const handleStartChange = (event) => {
    const startValue = replace(event.target.value, ",", ".");
    setStart(startValue);
    // Вычисляем новые значения reference, volumeDifference и formattedRelativeError
    const newVolumeDifference = (
      parseFloat(finish) - parseFloat(startValue)
    ).toFixed(3);
    const newRelativeError = newVolumeDifference
      ? (((1 - 0.01 / newVolumeDifference) * 100 * 100) / 100).toFixed(2)
      : "0";
    const newFormattedRelativeError = formatRelativeError(newRelativeError);
    const isNewMatchingVolume = reference === newVolumeDifference;
    const newBlockColor = isNewMatchingVolume
      ? "3px solid #2897bf"
      : "3px solid #ee6969";

    // Обновляем состояния
    setVolumeDifference(newVolumeDifference);
    setFormattedRelativeError(newFormattedRelativeError);
    setBlockColor(newBlockColor);
    handleSaveTestimony();
  };

  const handleFinishChange = (event) => {
    const finishValue = replace(event.target.value, ",", ".");
    setFinish(finishValue);
    // Вычисляем новые значения reference, volumeDifference и formattedRelativeError
    const newVolumeDifference = (
      parseFloat(finishValue) - parseFloat(start)
    ).toFixed(3);
    const newRelativeError = newVolumeDifference
      ? (((1 - 0.01 / newVolumeDifference) * 100 * 100) / 100).toFixed(2)
      : "0";
    const newFormattedRelativeError = formatRelativeError(newRelativeError);
    const isNewMatchingVolume = reference === newVolumeDifference;
    const newBlockColor = isNewMatchingVolume
      ? "3px solid #2897bf"
      : "3px solid #ee6969";

    // Обновляем состояния
    setVolumeDifference(newVolumeDifference);
    setFormattedRelativeError(newFormattedRelativeError);
    setBlockColor(newBlockColor);
    handleSaveTestimony();
  };

  /*----Устанавливаем формат цисла(цело и после точки)------ */
  const formatRelativeError = (value) => {
    const parsedValue = parseFloat(value);
    if (parsedValue % 1 === 0) {
      return parsedValue.toFixed(0);
    } else {
      return parsedValue.toFixed(2);
    }
  };

  /*----Данные -2 ---  */
  const handleStart1Change = (event) => {
    const startValue = replace(event.target.value, ",", ".");
    setStart1(startValue);

    // Вычисляем новые значения reference1, volumeDifference1 и formattedRelativeError1
    const newVolumeDifference1 = (
      parseFloat(finish1) - parseFloat(startValue)
    ).toFixed(3);
    const newRelativeError1 = newVolumeDifference1
      ? (((1 - 0.02 / newVolumeDifference1) * 100 * 100) / 100).toFixed(2)
      : "0";
    const newFormattedRelativeError1 = formatRelativeError1(newRelativeError1);
    const isNewMatchingVolume1 = reference1 === newVolumeDifference1;
    const newBlockColor1 = isNewMatchingVolume1
      ? "3px solid #2897bf"
      : "3px solid #ee6969";

    // Обновляем состояния с новыми значениями
    setVolumeDifference1(newVolumeDifference1);
    setFormattedRelativeError1(newFormattedRelativeError1);
    setBlockColor1(newBlockColor1);
    handleSaveTestimony();
  };

  const handleFinish1Change = (event) => {
    const finishValue = replace(event.target.value, ",", ".");
    setFinish1(finishValue);

    // Вычисляем новые значения reference1, volumeDifference1 и formattedRelativeError1
    const newVolumeDifference1 = (
      parseFloat(finishValue) - parseFloat(start1)
    ).toFixed(3);
    const newRelativeError1 = newVolumeDifference1
      ? (((1 - 0.02 / newVolumeDifference1) * 100 * 100) / 100).toFixed(2)
      : "0";
    const newFormattedRelativeError1 = formatRelativeError1(newRelativeError1);
    const isNewMatchingVolume1 = reference1 === newVolumeDifference1;
    const newBlockColor1 = isNewMatchingVolume1
      ? "3px solid #2897bf"
      : "3px solid #ee6969";

    // Обновляем состояния с новыми значениями
    setVolumeDifference1(newVolumeDifference1);
    setFormattedRelativeError1(newFormattedRelativeError1);
    setBlockColor1(newBlockColor1);
    handleSaveTestimony();
  };

  /*----Устанавливаем формат цисла(цело и после точки)------ */
  const formatRelativeError1 = (value) => {
    const parsedValue = parseFloat(value);
    if (parsedValue % 1 === 0) {
      return parsedValue.toFixed(0);
    } else {
      return parsedValue.toFixed(2);
    }
  };

  /*----Данные -3 ---  */
  const handleStart2Change = (event) => {
    const startValue = replace(event.target.value, ",", ".");
    setStart2(startValue);

    // Вычисляем новые значения reference2, volumeDifference2 и formattedRelativeError2
    const newVolumeDifference2 = (
      parseFloat(finish2) - parseFloat(startValue)
    ).toFixed(3);
    const newRelativeError2 = newVolumeDifference2
      ? (((1 - 0.1 / newVolumeDifference2) * 100 * 100) / 100).toFixed(2)
      : "0";
    const newFormattedRelativeError2 = formatRelativeError2(newRelativeError2);
    const isNewMatchingVolume2 = reference2 === newVolumeDifference2;
    const newBlockColor2 = isNewMatchingVolume2
      ? "3px solid #2897bf"
      : "3px solid #ee6969";

    // Обновляем состояния с новыми значениями
    setVolumeDifference2(newVolumeDifference2);
    setFormattedRelativeError2(newFormattedRelativeError2);
    setBlockColor2(newBlockColor2);
    handleSaveTestimony();
  };

  const handleFinish2Change = (event) => {
    const finishValue = replace(event.target.value, ",", ".");
    setFinish2(finishValue);

    // Вычисляем новые значения reference2, volumeDifference2 и formattedRelativeError2
    const newVolumeDifference2 = (
      parseFloat(finishValue) - parseFloat(start2)
    ).toFixed(3);
    const newRelativeError2 = newVolumeDifference2
      ? (((1 - 0.1 / newVolumeDifference2) * 100 * 100) / 100).toFixed(2)
      : "0";
    const newFormattedRelativeError2 = formatRelativeError2(newRelativeError2);
    const isNewMatchingVolume2 = reference2 === newVolumeDifference2;
    const newBlockColor2 = isNewMatchingVolume2
      ? "3px solid #2897bf"
      : "3px solid #ee6969";

    // Обновляем состояния с новыми значениями
    setVolumeDifference2(newVolumeDifference2);
    setFormattedRelativeError2(newFormattedRelativeError2);
    setBlockColor2(newBlockColor2);
    handleSaveTestimony();
  };

  const handleConsumptionChange = (event) => {
    const consumptionValue = replace(event.target.value, ",", ".");
    setConsumption(consumptionValue);
    handleSaveTestimony();
  };

  /*----Устанавливаем формат цисла(цело и после точки)------ */
  const formatRelativeError2 = (value) => {
    const parsedValue = parseFloat(value);
    if (parsedValue % 1 === 0) {
      return parsedValue.toFixed(0);
    } else {
      return parsedValue.toFixed(2);
    }
  };

  return (
    <div className="testimony_box1">
      <div className="testimony">
        <h2>Определение относительной погрешности</h2>
      </div>

      <div className="testimony__box">
        <div className="testimony__box-items">
          <div className="testimony__box-items-top">
            <div className="testimony__box-consumption">
              <p>
                Расход <span>0.03</span> м³/ч
              </p>
            </div>

            <div className="testimony__box-start">
              <p>Показания на начало измерений:</p>
              <input
                placeholder="Показания в м³"
                value={start}
                onChange={handleStartChange}
              />
            </div>

            <div className="testimony__box-finish">
              <p>Показания на конец измерений:</p>
              <input
                placeholder="Показания в м³"
                value={finish}
                onChange={handleFinishChange}
              />
            </div>
          </div>

          {start !== "" && finish !== "" && (
            <div className="testimony__box-items-bottom">
              <div
                className="testimony__box-items-bottom_box"
                style={{ border: blockColor }}
              >
                <p>
                  Объём воды по показаниям эталона: <span>{reference}</span> м³
                </p>
                <p>
                  Объём воды по показаниям счётчика:{" "}
                  <span>{volumeDifference}</span> м³
                </p>
                <p>
                  Относительная погрешность счётчика:{" "}
                  <span>{formattedRelativeError}</span>%
                </p>
                <p>
                  Пределы допускаемой погрешности счётчика: <span>±5</span>%
                </p>
              </div>
            </div>
          )}
        </div>

        <div className="testimony__box-volume">
          <p>
            Объём воды по показаниям эталона: <span>0.010</span> м³
          </p>
        </div>
      </div>

      <div className="testimony__box">
        <div className="testimony__box-items">
          <div className="testimony__box-items-top">
            <div className="testimony__box-consumption">
              <p>
                Расход <span>0.132</span> м³/ч
              </p>
            </div>
            <div className="testimony__box-start">
              <p>Показания на начало измерений:</p>
              <input
                placeholder="Показания в м³"
                value={start1}
                onChange={handleStart1Change}
              />
            </div>
            <div className="testimony__box-finish">
              <p>Показания на конец измерений:</p>
              <input
                placeholder="Показания в м³"
                value={finish1}
                onChange={handleFinish1Change}
              />
            </div>
          </div>

          {start1 !== "" && finish1 !== "" && (
            <div className="testimony__box-items-bottom">
              <div
                className="testimony__box-items-bottom_box"
                style={{ border: blockColor1 }}
              >
                <p>
                  Объём воды по показаниям эталона: <span>{reference1}</span> м³
                </p>
                <p>
                  Объём воды по показаниям счётчика:
                  <span>{volumeDifference1}</span>
                  м³
                </p>
                <p>
                  Относительная погрешность счётчика:{" "}
                  <span>{formattedRelativeError1}</span>%
                </p>
                <p>
                  Пределы допускаемой погрешности счётчика:<span>±2</span> %
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="testimony__box-volume">
          <p>
            Объём воды по показаниям эталона: <span>0.020</span> м³
          </p>
        </div>
      </div>

      <div className="testimony__box">
        <div className="testimony__box-items">
          <div className="testimony__box-items-top">
            <div className="testimony__box-start">
              <p>Расход м³/ч:</p>
              <input
                placeholder="Показания в м³"
                value={consumption}
                onChange={handleConsumptionChange}
              />
            </div>
            <div className="testimony__box-start">
              <p>Показания на начало измерений:</p>
              <input
                placeholder="Показания в м³"
                value={start2}
                onChange={handleStart2Change}
              />
            </div>
            <div className="testimony__box-finish">
              <p>Показания на конец измерений:</p>
              <input
                placeholder="Показания в м³"
                value={finish2}
                onChange={handleFinish2Change}
              />
            </div>
          </div>

          {start2 !== "" && finish2 !== "" && (
            <div className="testimony__box-items-bottom">
              <div
                className="testimony__box-items-bottom_box"
                style={{ border: blockColor2 }}
              >
                <p>
                  Объём воды по показаниям эталона: <span>{reference2}</span> м³
                </p>
                <p>
                  Объём воды по показаниям счётчика:
                  <span>{volumeDifference2}</span>
                  м³
                </p>
                <p>
                  Относительная погрешность счётчика:{" "}
                  <span>{formattedRelativeError2}</span>%
                </p>
                <p>
                  Пределы допускаемой погрешности счётчика:<span>±2</span> %
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="testimony__box-volume">
          <p>
            Объём воды по показаниям эталона: <span>0.100</span> м³
          </p>
        </div>
      </div>
    </div>
  );
};

export default Testimony;
