import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FaSave } from "react-icons/fa";

const NewMeters = () => {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("accessToken");

  /*-------- pop-up статус------- */
  const [statusOpen, setStatusOpne] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(0);
  const listStatus = ["Верифицированный", "Архивный"];
  const onClickListStatus = (i) => {
    setSelectedStatus(i);
    setStatusOpne(false);
  };

  const selectedStatusName = listStatus[selectedStatus];

  /*------ counter холодная вода ---------*/
  const [selectedCold, setSelectedCold] = useState(0);
  const handleIncrement = (type) => {
    if (type === "cold") {
      setSelectedCold((prevValue) => prevValue + 0.5);
    } else if (type === "hot") {
      setSelectedHot((prevValue) => prevValue + 0.5);
    }
  };

  /*------ counter горячая вода ---------*/
  const [selectedHot, setSelectedHot] = useState(0);
  const handleDecrement = (type) => {
    if (type === "cold" && selectedCold > 0) {
      setSelectedCold((prevValue) => prevValue - 0.5);
    } else if (type === "hot" && selectedHot > 0) {
      setSelectedHot((prevValue) => prevValue - 0.5);
    }
  };

  const [meterData, setMeterData] = useState({
    name_si: "",
    number_registry: "",
    number: "",
    manufacturer: {
      name: "",
    },
  });

  const handleCasesClick = async () => {
    try {
      const apiUrl = "https://app.metriva.ru/api/staff/meters";
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      };

      const updateMeterData = {
        ...meterData,
        cold: selectedCold,
        hot: selectedHot,
        manufacturer: {
          name: meterData.manufacturer.name,
        },
        status:
          selectedStatusName === "Верифицированный" ? "verified" : "archive",
      };

      const response = await axios.post(apiUrl, updateMeterData, { headers });
      console.log(response.data);

      setMeterData({
        number: "",
        number_registry: "",
        name_si: "",
        manufacturer: {
          name: "",
        },
      });

      alert("Прибор создан.");
      navigate("/admin/meters");
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const anyFieldFilled = Object.values(meterData).some(
          (value) => typeof value === "string" && value.trim() !== ""
        );

        if (anyFieldFilled) {
          let errorMessage = "";

          if (
            !meterData.manufacturer.name ||
            !meterData.manufacturer.name.trim()
          ) {
            errorMessage += 'Поле "Изготовитель" обязательно для заполнения.\n';
          }
          if (!meterData.number || !meterData.number.trim()) {
            errorMessage += 'Поле "№ ФИФ/Аршин" обязательно для заполнения.\n';
          }

          // Проверяем, есть ли ошибка валидации на номер
          const validationErrors = error.response.data.errors;
          if (validationErrors && validationErrors.number) {
            errorMessage += `В поле № ФИФ/Аршин", ${validationErrors.number.join(
              ", "
            )}\n`;
          }

          alert(errorMessage);
        } else {
          // Если ни одно поле не заполнено, показываем общее сообщение
          alert("Все поля обязательны для заполнения .\n");
        }
      } else {
        console.error(error);
        alert("Ошибка на сервере!");
      }
    }
  };

  return (
    <div>
      <div className="add">
        <div className="add_1">
          <h2>Добавить базу приборов</h2>

          <div className="add__container">
            <div className="selected_meter">
              <label>№ в реестре (ФИФ):</label>
              <input
                value={meterData.number_registry}
                onChange={(e) =>
                  setMeterData((prevMeterData) => ({
                    ...prevMeterData,
                    number_registry: e.target.value,
                  }))
                }
              />
            </div>

            <div className="selected_meter">
              <label>№ ФИФ/Аршин:</label>
              <input
                value={meterData.number}
                onChange={(e) =>
                  setMeterData((prevMeterData) => ({
                    ...prevMeterData,
                    number: e.target.value,
                  }))
                }
              />
            </div>

            <div className="selected_meter">
              <label>Наименование СИ:</label>
              <input
                value={meterData.name_si}
                onChange={(e) =>
                  setMeterData((prevMeterData) => ({
                    ...prevMeterData,

                    name_si: e.target.value,
                  }))
                }
              />
            </div>

            <div className="selected_meter">
              <label>Изготовитель:</label>
              <input
                value={meterData.manufacturer.name}
                onChange={(e) =>
                  setMeterData((prevMeterData) => ({
                    ...prevMeterData,
                    manufacturer: {
                      name: e.target.value,
                    },
                  }))
                }
              />
            </div>

            <div className="selected_meter">
              <label>МПИ:</label>
              <input
                value={meterData.mpi}
                onChange={(e) =>
                  setMeterData((prevMeterData) => ({
                    ...prevMeterData,

                    mpi: e.target.value,
                  }))
                }
              />
            </div>

            <div className="selected_meter">
              <label>Статус прибора :</label>

              <div className="protocol_categories-box1">
                <div
                  className="protocol_meters_top"
                  onClick={() => setStatusOpne(!statusOpen)}
                >
                  {selectedStatusName}
                  <span className="fas fa-angle-down"></span>
                </div>

                {statusOpen && (
                  <div className="protocol_meters_bottom">
                    {listStatus.map((name, i) => (
                      <p key={i} onClick={() => onClickListStatus(i)}>
                        {name}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className="selected_meter">
              <label>Интервал поверки, лет (ХВС):</label>
              <div className="selected_box_counter">
                <button
                  className="btn_selected_box_counter"
                  onClick={() => handleDecrement("cold")}
                >
                  -
                </button>
                <span>{selectedCold}</span>
                <button
                  className="btn_selected_box_counter"
                  onClick={() => handleIncrement("cold")}
                >
                  +
                </button>
              </div>
            </div>

            <div className="selected_meter">
              <label>Интервал поверки, лет (ГВС):</label>
              <div className="selected_box_counter">
                <button
                  className="btn_selected_box_counter"
                  onClick={() => handleDecrement("hot")}
                >
                  -
                </button>
                <span>{selectedHot}</span>
                <button
                  className="btn_selected_box_counter"
                  onClick={() => handleIncrement("hot")}
                >
                  +
                </button>
              </div>
            </div>
          </div>

          <div className="btn_meters">
            <button className="button" onClick={handleCasesClick}>
              <FaSave className="button-icon" />
              <p>Сохранить</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewMeters;
