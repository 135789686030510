import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { FaPlus, FaSearch, FaEdit } from "react-icons/fa";
import LoadingText from "../LoadingText.js";

const Meters = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const accessToken = localStorage.getItem("accessToken");
  const role = localStorage.getItem("role");

  const [apiData, setApiData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState("verified");
  const [searchQuery, setSearchQuery] = useState("");
  const [originalApiData, setOriginalApiData] = useState([]);
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const [pagination, setPagination] = useState({
    current_page: 1,
    last_page: 1,
  });

  const [userData, setUserData] = useState({
    role: role,
  });

  const fetchData = async (page = 1, status = selectedStatus) => {
    try {
      setIsLoading(true);
      const apiUrl = `https://app.metriva.ru/api/staff/meters?page=${page}&status=${status}`;
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      };
      const response = await axios.get(apiUrl, { headers });

      setPagination({
        current_page: response.data.meta.current_page,
        last_page: response.data.meta.last_page,
      });

      setApiData(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = parseInt(params.get("page"), 10) || 1;

    if (!params.has("page")) {
      navigate(`/admin/meters?page=${page}&status=${selectedStatus}`);
    }

    fetchData(page, selectedStatus);
  }, [location, selectedStatus, navigate]);

  useEffect(() => {
    if (searchQuery === "") {
      setApiData(originalApiData);
      return;
    }

    const filteredApiData = originalApiData.filter((meter) => {
      const searchTerm = searchQuery.toLowerCase();
      return (
        (meter.number_registry &&
          meter.number_registry.toLowerCase().includes(searchTerm)) ||
        (meter.number && meter.number.toLowerCase().includes(searchTerm)) ||
        (meter.name_si && meter.name_si.toLowerCase().includes(searchTerm)) ||
        (meter.manufacturer.name &&
          meter.manufacturer.name.toLowerCase().includes(searchTerm)) ||
        (meter.mpi && meter.mpi.toLowerCase().includes(searchTerm)) ||
        (meter.cold && meter.cold.toString().includes(searchTerm)) ||
        (meter.hot && meter.hot.toString().includes(searchTerm))
      );
    });

    setApiData(filteredApiData);
  }, [searchQuery, originalApiData]);

  const handlePageClick = (page) => {
    navigate(`/admin/meters?page=${page}&status=${selectedStatus}`);
  };

  const handleViewClick = (metersId) => {
    const selectedData = apiData.find((item) => item.id === metersId);
    navigate(`/admin/meters/${metersId}`, { state: { data: selectedData } });
  };

  const handleViewEditClick = (meterId) => {
    navigate(`/admin/meters/${meterId}/edit`);
  };

  const handleMetersClick = () => {
    navigate("/admin/meters/create");
  };

  /*------меняем статус---- */
  const changeMeterStatus = async (meterId) => {
    try {
      const apiUrl = "https://app.metriva.ru/api/staff/meters/status";
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      };
      const requestData = { id: meterId };
      const response = await axios.post(apiUrl, requestData, { headers });

      // Обработка успешного изменения статуса (если необходимо)
      console.log("Статус счётчика успешно изменен", response.data);

      // Вызов fetchData или другой подходящей функции для обновления данных после изменения статуса
      fetchData();
    } catch (error) {
      console.error("Ошибка при изменении статуса счётчика", error);
    }
  };

  const handleVerifiedClick = () => {
    setSelectedStatus("verified");
    const page = 1;
    navigate(`/admin/meters?page=${page}&status=verified`);
  };

  const handleArchiveClick = () => {
    setSelectedStatus("archive");
    const page = 1;
    navigate(`/admin/meters?page=${page}&status=archive`);
  };

  useEffect(() => {
    const storedRole = localStorage.getItem("role");
    if (role !== storedRole) {
      localStorage.setItem("role", storedRole);
      setUserData((prevUserData) => ({
        ...prevUserData,
        role: storedRole,
      }));
    }
  }, [role]);

  return (
    <div>
      <div className="header">
        <button onClick={handleMetersClick} className="create-button">
          <FaPlus className="create-icon" />
          Добавить
        </button>

        <div className="search-container">
          <input
            type="text"
            className="search-input"
            placeholder="Поиск"
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <button className="search-button">
            <FaSearch className="search-icon" />
          </button>
        </div>
      </div>

      <div className="main">
        <main className="main-container">
          <div className="categories">
            <button
              onClick={handleVerifiedClick}
              className={
                selectedStatus === "verified"
                  ? "categories-active active-border"
                  : ""
              }
            >
              Верифицированные
            </button>

            <button
              onClick={handleArchiveClick}
              className={
                selectedStatus === "archive"
                  ? "categories-active active-border"
                  : ""
              }
            >
              Архивные
            </button>
          </div>

          {isLoading ? (
            <LoadingText />
          ) : (
            <div className="attend">
              <div className="attendance">
                <div className="attendance-list">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>№ в реестре (ФИФ)</th>
                        <th>№ ФИФ/Аршин</th>
                        <th>Нименование СИ</th>
                        <th>Изготовитель</th>
                        <th>МПИ</th>
                        <th>Интервал поверки ХВС</th>
                        <th>Интервал поверки ГВС</th>
                        <th className="th">Просм.</th>
                        <th className="th">Статус</th>
                        {role === "super" && <th className="th">Ред.</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {apiData.map((meter) => (
                        <tr key={meter.id}>
                          <td>{meter.number_registry}</td>
                          <td>{meter.number}</td>
                          <td>{meter.name_si}</td>
                          <td>{meter.manufacturer.name}</td>
                          <td>{meter.mpi}</td>
                          <td>{meter.cold} лет</td>
                          <td>{meter.hot} лет</td>
                          <td
                            onClick={() => handleViewClick(meter.id)}
                            style={{ cursor: "pointer" }}
                          >
                            <span className="fas fa-eye download-icon"></span>
                          </td>
                          <td>
                            {selectedStatus === "verified" ? (
                              <span
                                className="fas fa-archive download-icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => changeMeterStatus(meter.id)}
                              ></span>
                            ) : (
                              <span
                                className="fas fa-history download-icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => changeMeterStatus(meter.id)}
                              ></span>
                            )}
                          </td>
                          {role === "super" && (
                            <td
                              onClick={() => handleViewEditClick(meter.id)}
                              style={{ cursor: "pointer" }}
                            >
                              <FaEdit className="download-icon" />
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <div className="pagination">
                    {Array.from(
                      { length: pagination.last_page },
                      (_, i) => i + 1
                    ).map((page) => (
                      <button
                        key={page}
                        className={
                          pagination.current_page === page
                            ? "active_paginate"
                            : ""
                        }
                        onClick={() => handlePageClick(page)}
                      >
                        {page}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export default Meters;
