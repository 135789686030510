import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

const BlockTick = ({ onOutShowBox, onTestingBox }) => {
  const accessToken = localStorage.getItem("accessToken");
  const { protocols_id } = useParams();
  const [protocolFormData, setProtocolFormData] = useState({});

  const [isOut, setIsOut] = useState(false);
  const handleOutShowBox = (event) => {
    const isOut = event.target.checked;
    setIsOut(isOut);
    onOutShowBox(isOut);
  };

  const [isTesting, setIsTesting] = useState(false);
  const handleTesting = (event) => {
    const isTesting = event.target.checked;
    setIsTesting(isTesting);
    onTestingBox(isTesting);
  };

  /*-----получаем данные от бэкэнд(первоначальное состояние)---- */
  useEffect(() => {
    const fetchProtocolData = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        };

        const response = await axios.get(
          `https://app.metriva.ru/api/staff/protocols/${protocols_id}/edit`,
          { headers }
        );

        const protocolData = response.data.data;
        setProtocolFormData(protocolData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchProtocolData();
  }, [protocols_id]);

  useEffect(() => {
    // Проверка, что данные от бэкэнда получены
    if (protocolFormData.mechanical_accepted !== undefined) {
      setIsOut(protocolFormData.mechanical_accepted);
      onOutShowBox(protocolFormData.mechanical_accepted);
    }
    if (protocolFormData.tightness_accepted !== undefined) {
      setIsTesting(protocolFormData.tightness_accepted);
      onTestingBox(protocolFormData.tightness_accepted);
    }
  }, [protocolFormData]);

  return (
    <div>
      <div className="inspection">
        <div className="inspection__text">
          <h2>Внешний осмотр</h2>
          <p>
            Раздел 2 «Методика поверки «Pr»: есть возможность визуально
            считывать показания счётчика, не нарушена целостность счётного
            механизма счетчика, отсутствуют механические повреждения на корпусе
            счётчика, комплектность счётчика соответствует описанию типа, знак
            утверждения типа нанесён на счётчик в установленном виде.
          </p>
        </div>
        <div className="inspection__items">
          <label>
            <input
              type="checkbox"
              checked={isOut}
              onChange={handleOutShowBox}
            />
            <p>Соответствует пункту 2.7.1 Методики поверки МИ 1592-2015</p>
          </label>
        </div>
      </div>

      <div className="inspection">
        <div className="inspection__text">
          <h2>Опробование</h2>
          <p>
            Раздел 2 «Методика поверки «Pr»: после выдержки в течение 5 минут в
            местах соединений и на корпусах счётчиков и эталона не наблюдается
            каплевыделений и течи воды.
          </p>
        </div>
        <div className="inspection__items">
          <label>
            <input
              type="checkbox"
              checked={isTesting}
              onChange={handleTesting}
            />
            <p>Соответствует пункту 2.7.1 Методики поверки МИ 1592-2015</p>
          </label>
        </div>
      </div>
    </div>
  );
};

export default BlockTick;
