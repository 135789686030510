import React, { useState } from "react";

const BlockTick = ({ onOutShowBox, onTestingBox }) => {
  const [isOut, setIsOut] = useState(false);
  const handleOutShowBox = (event) => {
    const isOut = event.target.checked;
    setIsOut(isOut);
    onOutShowBox(isOut);
  };

  const [isTesting, setIsTesting] = useState(false);
  const handleTesting = (event) => {
    const isTesting = event.target.checked;
    setIsTesting(isTesting);
    onTestingBox(isTesting);
  };

  return (
    <div>
      <div className="inspection">
        <div className="inspection__text">
          <h2>Внешний осмотр</h2>
          <p>
            Раздел 2 «Методика поверки «Pr»: есть возможность визуально
            считывать показания счётчика, не нарушена целостность счётного
            механизма счетчика, отсутствуют механические повреждения на корпусе
            счётчика, комплектность счётчика соответствует описанию типа, знак
            утверждения типа нанесён на счётчик в установленном виде.
          </p>
        </div>
        <div className="inspection__items">
          <label>
            <input
              type="checkbox"
              checked={isOut}
              onChange={handleOutShowBox}
            />
            <p>Соответствует пункту 2.7.1 Методики поверки МИ 1592-2015</p>
          </label>
        </div>
      </div>

      <div className="inspection">
        <div className="inspection__text">
          <h2>Опробование</h2>
          <p>
            Раздел 2 «Методика поверки «Pr»: после выдержки в течение 5 минут в
            местах соединений и на корпусах счётчиков и эталона не наблюдается
            каплевыделений и течи воды.
          </p>
        </div>
        <div className="inspection__items">
          <label>
            <input
              type="checkbox"
              checked={isTesting}
              onChange={handleTesting}
            />
            <p>Соответствует пункту 2.7.1 Методики поверки МИ 1592-2015</p>
          </label>
        </div>
      </div>
    </div>
  );
};

export default BlockTick;
