import React, { useState, useEffect } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { startOfDay, addDays, addYears, isAfter, isBefore } from "date-fns";

const DateComponent = ({
  selectedWater,
  onSaveDateInNewProtocol,
  selectedRegistryId,
}) => {
  const accessToken = localStorage.getItem("accessToken");

  const [waterData, setWaterData] = useState({});
  const [nextVerificationDate, setNextVerificationDate] = useState(null);
  const [verificationDate, setVerificationDate] = useState(new Date());

  useEffect(() => {
    fetchWaterData();
  }, [selectedRegistryId, selectedWater]);

  const fetchWaterData = async () => {
    try {
      let response = await axios.get(
        "https://app.metriva.ru/api/staff/meters",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("полученные данные от бэкэнда в дате:", response.data.data);

      const selectedWaterData = response.data.data.find(
        (water) => water.id === selectedRegistryId
      );

      if (selectedWaterData) {
        setWaterData(selectedWaterData);
        updateNextVerificationDate(selectedWater, selectedWaterData);
      } else {
        console.error("Данные не найдены для выбранного id");
      }
    } catch (error) {
      console.error("Ошибка при получении данных с сервера:", error);
    }
  };

  useEffect(() => {
    // Обновляем дату следующей поверки при изменении верификационной даты
    updateNextVerificationDate(selectedWater, waterData);
  }, [verificationDate, selectedWater, waterData]);

  const updateNextVerificationDate = (selectedWaterType, selectedWaterData) => {
    const coldValue = parseFloat(selectedWaterData.cold);
    const hotValue = parseFloat(selectedWaterData.hot);

    if (selectedWaterType === "cold" && !isNaN(coldValue)) {
      const nextDate = addYears(verificationDate, Math.floor(coldValue));
      const nextVerificationDate = addDays(nextDate, -1);
      setNextVerificationDate(nextVerificationDate);
      onSaveDateInNewProtocol(verificationDate, nextVerificationDate);
    } else if (selectedWaterType === "hot" && !isNaN(hotValue)) {
      const nextDate = addYears(verificationDate, Math.floor(hotValue));
      const nextVerificationDate = addDays(nextDate, -1);
      setNextVerificationDate(nextVerificationDate);
      onSaveDateInNewProtocol(verificationDate, nextVerificationDate);
    }
  };

  const handleDateChange = (date) => {
    setVerificationDate(date);
    updateNextVerificationDate(date, waterData);
  };

  return (
    <div className="form__footer">
      <div className="form__footer-items">
        <div>
          <p>Дата поверки:</p>
          <div className="calendar">
            <span className="far fa-calendar-alt"></span>
            <DatePicker
              dateFormat="yyyy-MM-dd"
              placeholderText="Выберите дату"
              maxDate={new Date()} // Ограничиваем максимальную дату текущим днем
              filterDate={(date) =>
                isAfter(date, addDays(startOfDay(new Date()), -70))
              } // Ограничиваем выбор за последние 70 дней
              selected={verificationDate}
              onChange={handleDateChange}
            />
          </div>
        </div>
        <div>
          <p>Дата следующей поверки:</p>
          <div className="calendar">
            <span className="far fa-calendar-alt"></span>
            <DatePicker
              className="next_date_protocol"
              dateFormat="yyyy-MM-dd"
              placeholderText=""
              disabled
              selected={nextVerificationDate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateComponent;
