import React, { useState } from "react";

import HomeLeft from "../components/HomeLeft";
import HomeRight from "../components/HomeRight";

const Home = () => {
  return (
    <div className="container__home">
      <div>
        <HomeLeft />
      </div>
      <div>
        <HomeRight />
      </div>
    </div>
  );
};

export default Home;
