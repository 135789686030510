import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

const ShowUser = () => {
  const accessToken = localStorage.getItem("accessToken");
  const location = useLocation();
  const { data, apiData, userId } = location.state || {};
  const [protocolData, setProtocolData] = useState(null);

  useEffect(() => {
    if (data) {
      setProtocolData(data);
    } else {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `https://app.metriva.ru/api/staff/users/${userId}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
              },
            }
          );
          setProtocolData(response.data);
        } catch (error) {
          console.error(error);
        }
      };

      fetchData();
    }
  }, [data, userId]);

  /*---------Перевод поля "Должность"/"Статус"------------ */
  const getRoleName = (role) => {
    const roleNames = {
      admin: "Администратор",
      metrologist: "Метролог",
      brigadier: "Руководитель",
    };
    return roleNames[role];
  };

  const getStatusName = (status) => {
    if (status === "active") {
      return "Активный";
    } else if (status === "not active") {
      return "Не активный";
    } else {
      return "";
    }
  };

  return (
    <div>
      <div className="main">
        <div className="meter__container">
          <div className="meter_box">
            {protocolData && (
              <>
                <div className="meter_title_box">
                  <p className="meter_title">Логин:</p>
                  <p>{protocolData.login}</p>
                </div>

                <div className="meter_title_box">
                  <p className="meter_title">Должность:</p>
                  <p>{getRoleName(protocolData.role)}</p>
                </div>

                <div className="meter_title_box">
                  <p className="meter_title">Имя в системе:</p>
                  <p>{protocolData.name}</p>
                </div>

                {/* <div className="meter_title_box">
                  <p className="meter_title">Имя в ФГИС ФСА:</p>
                  <p>{protocolData.fsa_name}</p>
                </div>

                <div className="meter_title_box">
                  <p className="meter_title">Cнилс:</p>
                  <p>{protocolData.snils}</p>
                </div> */}

                <div className="meter_title_box">
                  <p className="meter_title">Статус:</p>
                  <p>{getStatusName(protocolData.status)}</p>
                </div>

                <div className="meter_title_box">
                  <p className="meter_title">Комплект СИ:</p>
                  <p>{protocolData.caseRelation?.inner_name}</p>
                </div>

                <div className="meter_title_box">
                  <p className="meter_title">Email:</p>
                  <p>{protocolData.email}</p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowUser;
