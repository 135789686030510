import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { FaSave, FaPlus } from "react-icons/fa";

const EditCases = () => {
  const navigate = useNavigate();
  const { cases_id } = useParams();
  const [casesData, setCasesData] = useState(null);
  const accessToken = localStorage.getItem("accessToken");

  const [innerName, setInnerName] = useState("");
  const [link, setLink] = useState("");
  const [fif, setFif] = useState("");
  const [regNumber, setRegNumber] = useState("");
  const [nameCases, setNameCases] = useState("");
  const [regName, setRegName] = useState("");
  const [photoFile, setPhotoFile] = useState(null);
  const [deviceDescription, setDeviceDescription] = useState("");
  const [numberCases, setNumberCases] = useState("");
  const [deviceType, setDeviceType] = useState("");
  const [deviceSign, setDeviceSign] = useState("");
  const [deviceDescription1, setDeviceDescription1] = useState("");
  const [numberCases1, setNumberCases1] = useState("");
  const [deviceType1, setDeviceType1] = useState("");
  const [deviceSign1, setDeviceSign1] = useState("");
  const [deviceDescription2, setDeviceDescription2] = useState("");
  const [numberCases2, setNumberCases2] = useState("");
  const [deviceType2, setDeviceType2] = useState("");
  const [deviceSign2, setDeviceSign2] = useState("");
  const [deviceDescription3, setDeviceDescription3] = useState("");
  const [numberCases3, setNumberCases3] = useState("");
  const [deviceType3, setDeviceType3] = useState("");
  const [deviceSign3, setDeviceSign3] = useState("");
  const [deviceDescription4, setDeviceDescription4] = useState("");
  const [numberCases4, setNumberCases4] = useState("");
  const [deviceType4, setDeviceType4] = useState("");
  const [deviceSign4, setDeviceSign4] = useState("");
  const [fsaName, setFsaName] = useState("");
  const [arshinName, setArshinName] = useState("");
  const [snils, setSnils] = useState("");
  const [companyID, setCompanyID] = useState(null);
  const [year, setYear] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = `https://app.metriva.ru/api/staff/cases/${cases_id}/edit`;
        const headers = {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        };
        const response = await axios.get(apiUrl, { headers });
        setCasesData(response.data.data);

        setInnerName(response.data.data.inner_name);
        setFif(response.data.data.fif);
        setRegNumber(response.data.data.reg_number);
        setNameCases(response.data.data.name);
        setLink(response.data.data.link);
        setRegName(response.data.data.reg_name);
        setFsaName(response.data.data.fsa_name);
        setArshinName(response.data.data.arshin_name);
        setSnils(response.data.data.snils);
        setCompanyID(response.data.data.company_id);
        setYear(response.data.data.year);

        setNumberCases(response.data.data.kits[0].device_manufacturer_number);
        setDeviceDescription(response.data.data.kits[0].device_description);
        setDeviceType(response.data.data.kits[0].device_type);
        setDeviceSign(response.data.data.kits[0].device_sign);

        setNumberCases1(response.data.data.kits[1].device_manufacturer_number);
        setDeviceDescription1(response.data.data.kits[1].device_description);
        setDeviceType1(response.data.data.kits[1].device_type);
        setDeviceSign1(response.data.data.kits[1].device_sign);

        setNumberCases2(response.data.data.kits[2].device_manufacturer_number);
        setDeviceDescription2(response.data.data.kits[2].device_description);
        setDeviceType2(response.data.data.kits[2].device_type);
        setDeviceSign2(response.data.data.kits[2].device_sign);

        setNumberCases3(response.data.data.kits[3].device_manufacturer_number);
        setDeviceDescription3(response.data.data.kits[3].device_description);
        setDeviceType3(response.data.data.kits[3].device_type);
        setDeviceSign3(response.data.data.kits[3].device_sign);

        setNumberCases4(response.data.data.kits[4].device_manufacturer_number);
        setDeviceDescription4(response.data.data.kits[4].device_description);
        setDeviceType4(response.data.data.kits[4].device_type);
        setDeviceSign4(response.data.data.kits[4].device_sign);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  /*----функция для отправки данных на бэкэнд---- */
  const handleSave = async () => {
    try {
      const apiUrl = `https://app.metriva.ru/api/staff/cases/${cases_id}`;
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      };

      const kitsData = [
        {
          device_number: 1,
          device_type: deviceType,
          device_manufacturer_number: numberCases,
          device_description: deviceDescription,
          device_sign: deviceSign,
        },
        {
          device_number: 2,
          device_type: deviceType1,
          device_manufacturer_number: numberCases1,
          device_description: deviceDescription1,
          device_sign: deviceSign1,
        },
        {
          device_number: 3,
          device_type: deviceType2,
          device_manufacturer_number: numberCases2,
          device_description: deviceDescription2,
          device_sign: deviceSign2,
        },
        {
          device_number: 4,
          device_type: deviceType3,
          device_manufacturer_number: numberCases3,
          device_description: deviceDescription3,
          device_sign: deviceSign3,
        },
        {
          device_number: 5,
          device_type: deviceType4,
          device_manufacturer_number: numberCases4,
          device_description: deviceDescription4,
          device_sign: deviceSign4,
        },
      ].filter((kit) => {
        const nonEmptyValues = Object.values(kit).filter(
          (value, index) => index !== 0 && value !== ""
        );
        return nonEmptyValues.length > 0;
      });

      const data = {
        number: numberCases,
        link,
        fif,
        reg_number: regNumber,
        name: nameCases,
        reg_name: regName,
        inner_name: innerName,
        fsa_name: fsaName,
        arshin_name: arshinName,
        snils: snils,
        company_id: companyID,
        year: year,
        kits: kitsData,
      };

      const response = await axios.put(apiUrl, data, { headers });
      navigate("/admin/cases");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className="main">
        <div className="cases_container">
          <div className="cases_container_tab1">
            <div className="cases_container_title">
              <p>№ Комплекта</p>
            </div>
            <div className="cases_container_desc-edit">
              <input
                className="cases_container_desc-vol"
                value={innerName}
                onChange={(e) => setInnerName(e.target.value)}
              />
            </div>
          </div>
          <div className="cases_container_tab2">
            <div className="cases_container_title">
              <p>Наименование</p>
            </div>
            <div className="cases_container_desc-edit">
              <p className="cases_container_desc-vol-1">Заводской номер СИ</p>
              <div className="deviceType">
                <input
                  className="cases_container_desc-deviceType"
                  value={deviceType}
                  onChange={(e) => setDeviceType(e.target.value)}
                />

                <input
                  className="cases_container_desc-deviceSign"
                  value={deviceSign}
                  onChange={(e) => setDeviceSign(e.target.value)}
                />
              </div>

              <div className="deviceType">
                <input
                  className="cases_container_desc-deviceType"
                  value={deviceType1}
                  onChange={(e) => setDeviceType1(e.target.value)}
                />

                <input
                  className="cases_container_desc-deviceSign"
                  value={deviceSign1}
                  onChange={(e) => setDeviceSign1(e.target.value)}
                />
              </div>

              <div className="deviceType">
                <input
                  value={deviceType2}
                  onChange={(e) => setDeviceType2(e.target.value)}
                  style={{ width: "50%", height: "35px" }}
                />

                <input
                  value={deviceSign2}
                  onChange={(e) => setDeviceSign2(e.target.value)}
                  style={{ width: "50%" }}
                />
              </div>

              <div className="deviceType">
                <input
                  value={deviceType3}
                  onChange={(e) => setDeviceType3(e.target.value)}
                  style={{ width: "50%", height: "35px" }}
                />

                <input
                  value={deviceSign3}
                  onChange={(e) => setDeviceSign3(e.target.value)}
                  style={{ width: "50%" }}
                />
              </div>

              <div className="deviceType">
                {!deviceType4 && !deviceSign4 ? (
                  <p className="cases_container_desc-vol-edit">
                    Преобразователь оптоэлектрический «Око» ЛМ ОПТ1.00.03
                  </p>
                ) : (
                  <>
                    <input
                      className="cases_container_desc-deviceType"
                      value={deviceType4}
                      onChange={(e) => setDeviceType4(e.target.value)}
                      style={{ width: "75%", height: "35px" }}
                    />
                    <input
                      className="cases_container_desc-deviceSign"
                      value={deviceSign4}
                      onChange={(e) => setDeviceSign4(e.target.value)}
                      style={{ width: "25%" }}
                    />
                  </>
                )}
              </div>

              <p className="cases_container_desc-vol-edit">
                Мастер-поверитель АРШИН
              </p>
              <p className="cases_container_desc-vol-edit">СНИЛС</p>
              <p className="cases_container_desc-vol-edit">
                Мастер-поверитель ФСА
              </p>
            </div>
          </div>

          <div className="cases_container_tab3">
            <div className="cases_container_title">
              <p>Номер</p>
            </div>
            <div className="cases_container_desc-edit">
              <div className="cases_container_desc-vol-name">
                <input
                  value={fif}
                  onChange={(e) => setFif(e.target.value)}
                  style={{ width: "100%", height: "35px" }}
                />

                <input
                  value={regNumber}
                  onChange={(e) => setRegNumber(e.target.value)}
                  style={{ width: "100%", height: "35px" }}
                />
              </div>

              <input
                className="cases_container_desc-vol-edit"
                value={numberCases}
                onChange={(e) => setNumberCases(e.target.value)}
              />
              <input
                className="cases_container_desc-vol-edit"
                value={numberCases1}
                onChange={(e) => setNumberCases1(e.target.value)}
              />
              <input
                className="cases_container_desc-vol-edit"
                value={numberCases2}
                onChange={(e) => setNumberCases2(e.target.value)}
              />
              <input
                className="cases_container_desc-vol-edit"
                value={numberCases3}
                onChange={(e) => setNumberCases3(e.target.value)}
              />
              <input
                className="cases_container_desc-vol-edit"
                value={numberCases4}
                onChange={(e) => setNumberCases4(e.target.value)}
              />
              <input
                className="cases_container_desc-vol-edit"
                value={arshinName}
                onChange={(e) => setArshinName(e.target.value)}
              />
              <input
                className="cases_container_desc-vol-edit"
                value={snils}
                onChange={(e) => setSnils(e.target.value)}
              />
              <input
                className="cases_container_desc-vol-edit"
                value={fsaName}
                onChange={(e) => setFsaName(e.target.value)}
              />
            </div>
          </div>

          <div className="cases_container_tab4">
            <div className="cases_container_title">
              <p>Описание</p>
            </div>
            <div className="cases_container_desc-edit">
              <div className="cases_container_desc-link">
                <input
                  className="cases_container_desc-vol-edit"
                  value={nameCases}
                  onChange={(e) => setNameCases(e.target.value)}
                />
                <input
                  className="cases_container_desc-vol"
                  value={regName}
                  onChange={(e) => setRegName(e.target.value)}
                />

                <input
                  className="cases_container_desc-vol"
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                />
              </div>
              <input
                className="cases_container_desc-vol"
                value={deviceDescription}
                onChange={(e) => setDeviceDescription(e.target.value)}
              />
              <input
                className="cases_container_desc-vol"
                value={deviceDescription1}
                onChange={(e) => setDeviceDescription1(e.target.value)}
              />
              <input
                className="cases_container_desc-vol"
                value={deviceDescription2}
                onChange={(e) => setDeviceDescription2(e.target.value)}
              />
              <input
                className="cases_container_desc-vol"
                value={deviceDescription3}
                onChange={(e) => setDeviceDescription3(e.target.value)}
              />
              <input
                className="cases_container_desc-vol"
                value={deviceDescription4}
                onChange={(e) => setDeviceDescription4(e.target.value)}
              />
              <input className="cases_container_desc-vol" />
              <input className="cases_container_desc-vol" />
              <input className="cases_container_desc-vol" />
            </div>
          </div>
          <div className="cases_container_tab5">
            <div className="cases_container_title">
              <p>Фотография</p>
            </div>
            <div className="cases_container_desc-edit">
              <label className="custom-file-input">
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => setPhotoFile(e.target.files[0])}
                />
                <span>Выберите фото</span>
              </label>
              {photoFile && (
                <div className="selected-photo">
                  <img
                    src={URL.createObjectURL(photoFile)}
                    alt="Выбранная фотография"
                    style={{
                      maxHeight: "100%",
                      maxWidth: "90%",
                      objectFit: "contain",
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="btn_cases">
          <button className="button-cases" onClick={handleSave}>
            <FaSave className="button-icon" />
            <p>Сохранить</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditCases;
