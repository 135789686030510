import React, { useState } from "react";

const Name = ({ onSaveName }) => {
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [ownerInitialOptional, setOwnerInitialOptional] = useState("");

  const handleLastNameChange = (event) => {
    const lastNameValue = event.target.value.replace(/\s/g, "");
    const formattedLastName =
      lastNameValue.charAt(0).toUpperCase() + lastNameValue.slice(1);
    setLastName(formattedLastName);
    onSaveName(formattedLastName, firstName, ownerInitialOptional);
  };

  const handleFirstNameChange = (event) => {
    const firstNameValue = event.target.value.replace(/\s/g, "");
    const formattedFirstName =
      firstNameValue.charAt(0).toUpperCase() + firstNameValue.slice(1);
    setFirstName(formattedFirstName);
    onSaveName(lastName, formattedFirstName, ownerInitialOptional);
  };

  const handleOwnerInitialOptional = (event) => {
    const ownerInitialOptionalValue = event.target.value.replace(/\s/g, "");
    const formattedOwnerInitialOptional =
      ownerInitialOptionalValue.charAt(0).toUpperCase() +
      ownerInitialOptionalValue.slice(1);
    setOwnerInitialOptional(formattedOwnerInitialOptional);
    onSaveName(lastName, firstName, formattedOwnerInitialOptional);
  };

  return (
    <div className="name">
      <div className="name__text">
        <p>ФИО заказчика:</p>
      </div>
      <div className="name__box">
        <div className="name__box-name">
          <input
            placeholder="Фамилия"
            value={lastName}
            onChange={handleLastNameChange}
          />
        </div>
        <div className="name__box-name">
          <input
            placeholder="Имя"
            value={firstName}
            onChange={handleFirstNameChange}
          />
        </div>
        <div className="name__box-name">
          <input
            placeholder="Отчество"
            value={ownerInitialOptional}
            onChange={handleOwnerInitialOptional}
          />
        </div>
      </div>
    </div>
  );
};

export default Name;
