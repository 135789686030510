import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FaSave } from "react-icons/fa";

const NewCases = () => {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("accessToken");
  const [kitDataList, setKitDataList] = useState([]);

  /*--------number для бэкэнда */
  const [caseNumber, setCaseNumber] = useState(0);

  /*-------- pop-up компания */
  const [companyOpen, setCompanyOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(0);
  const [companies, setCompanies] = useState([]);
  const onClickListCompany = (id) => {
    setSelectedCompany(id);
    setCompanyOpen(false);
  };
  const selectedCompanyNme = companies[selectedCompany]?.name;

  /*------ pop-up год ---------*/
  const [yearOpen, setYearOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState(0);
  const listYear = Array.from({ length: 24 }, (_, index) =>
    (2000 + index).toString()
  );
  const onClickListYear = (i) => {
    setSelectedYear(i);
    setYearOpen(false);
  };
  const selectedYearName = listYear[selectedYear];

  const [caseData, setCaseData] = useState({
    number: "",
    link: "",
    fif: "",
    reg_number: "",
    name: "",
    reg_name: "",
    year: "",
    inner_name: "",
    fsa_name: "",
    arshin_name: "",
    snils: "",
    company_id: "",
    kits: [],
  });

  const [kitData, setKitData] = useState({
    device_number: "",
    device_type: "",
    device_manufacturer_number: "",
    device_description: "",
    device_sign: "",
  });

  const handleCasesClick = async () => {
    try {
      const apiUrl = "https://app.metriva.ru/api/staff/cases";
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      };

      const selectedYearValue = listYear[selectedYear];

      const updateCasesData = {
        ...caseData,
        number: (caseNumber + 1).toString(),
        company_id: companies[selectedCompany]?.id,
        name: caseData.name,
        year: selectedYearValue,
        fif: caseData.fif,
        link: caseData.link,
        reg_number: caseData.reg_number,
        reg_name: caseData.reg_name,
        inner_name: caseData.inner_name,
        fsa_name: caseData.fsa_name,
        arshin_name: caseData.arshin_name,
        snils: caseData.snils,

        kits: [...kitDataList],
      };

      const response = await axios.post(apiUrl, updateCasesData, { headers });
      console.log(response.data);

      setCaseNumber((prevCaseNumber) => prevCaseNumber + 1);

      // Очистка формы после отправки данных
      setCaseData({
        number: "",
        link: "",
        fif: "",
        reg_number: "",
        name: "",
        reg_name: "",
        year: "",
        inner_name: "",
        company_id: "",
        fsa_name: "",
        arshin_name: "",
        snils: "",
      });

      setKitData({
        device_number: "",
        device_type: "",
        device_manufacturer_number: "",
        device_description: "",
        device_sign: "",
      });

      alert("Средство измерения создано.");

      navigate("/admin/cases");
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const anyFieldFilled = Object.values(caseData).some(
          (value) => typeof value === "string" && value.trim() !== ""
        );

        if (anyFieldFilled) {
          let errorMessage = "";

          if (!caseData.fif || !caseData.fif.trim()) {
            errorMessage += 'Поле "Номер ФИФ" обязательно для заполнения.\n';
          }
          if (!caseData.inner_name || !caseData.inner_name.trim()) {
            errorMessage +=
              'Поле "Внутренний номер СИ" обязательно для заполнения.\n';
          }
          if (!caseData.kits || caseData.kits.length === 0) {
            errorMessage += 'Поля "Устройство" обязательны для заполнения.\n';
          }
          if (!caseData.link || !caseData.link.trim()) {
            errorMessage += 'Поле "Ссылка" обязательно для заполнения.\n';
          }
          if (!caseData.name || !caseData.name.trim()) {
            errorMessage += 'Поле "Наименование" обязательно для заполнения.\n';
          }
          if (!caseData.reg_name || !caseData.reg_name.trim()) {
            errorMessage +=
              'Поле "Регистрационное имя" обязательно для заполнения.\n';
          }
          if (!caseData.reg_number || !caseData.reg_number.trim()) {
            errorMessage +=
              'Поле "Регистрационный номер" обязательно для заполнения.\n';
          }

          alert(errorMessage);
        } else {
          // Если ни одно поле не заполнено, показываем общее сообщение
          alert("Все поля обязательны для заполнения\n");
        }
      } else {
        console.error(error);
        alert("Ошибка на сервере!");
      }
    }
  };

  /*------Получаем данные от бэкэнд в pop-up Компнаии */
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const apiUrl = "https://app.metriva.ru/api/staff/companies/list";
        const headers = {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        };
        const response = await axios.get(apiUrl, { headers });
        const companies = response.data.data;
        setCompanies(companies);
      } catch (error) {
        console.error(error);
      }
    };

    fetchCompanies();
  }, []);

  const handleAddDevice = () => {
    if (kitDataList.length >= 5) {
      return;
    }

    const deviceNames = [
      "Установка поверочная переносная",
      "Секундомер электронный",
      "Термогигрометр",
      "Термометр цифровой",
      "Преобразователь оптоэлектрический",
    ];

    const updatedKitDataList = [
      ...kitDataList,
      {
        device_number: "",
        device_type: "",
        device_manufacturer_number: "",
        device_description: "",
        device_sign: "",
      },
    ];

    if (kitDataList.length < deviceNames.length) {
      updatedKitDataList[kitDataList.length].device_type =
        deviceNames[kitDataList.length];
    }

    setKitDataList(updatedKitDataList);
  };

  const handleDeleteDevice = (index) => {
    setKitDataList((prevKitDataList) => {
      const updatedKits = [...prevKitDataList];
      updatedKits.splice(index, 1);
      return updatedKits;
    });
  };

  return (
    <div>
      <div className="add">
        <div className="add_1">
          <h2>Добавить средство поверки:</h2>

          <div className="add_2">
            <label>Внутренний номер СИ</label>
            <input
              value={caseData.inner_name}
              onChange={(e) =>
                setCaseData((prevCaseData) => ({
                  ...prevCaseData,
                  inner_name: e.target.value,
                }))
              }
            />
          </div>

          <div className="add__container">
            <div className="add_2">
              <label>Номер</label>
              <input
                value={caseData.number}
                onChange={(e) =>
                  setCaseData((prevCaseData) => ({
                    ...prevCaseData,
                    number: e.target.value,
                  }))
                }
              />
            </div>

            <div className="add_2">
              <label>Наименование</label>
              <input
                value={caseData.name}
                onChange={(e) =>
                  setCaseData((prevCaseData) => ({
                    ...prevCaseData,
                    name: e.target.value,
                  }))
                }
              />
            </div>

            <div className="add_2">
              <label>Регистрационный номер</label>
              <input
                value={caseData.reg_number}
                onChange={(e) =>
                  setCaseData((prevCaseData) => ({
                    ...prevCaseData,
                    reg_number: e.target.value,
                  }))
                }
              />
            </div>

            <div className="add_2">
              <label>Заводской номер СИ</label>
              <input
                value={caseData.fif}
                onChange={(e) =>
                  setCaseData((prevCaseData) => ({
                    ...prevCaseData,
                    fif: e.target.value,
                  }))
                }
              />
            </div>

            <div className="add_2">
              <label>Ссылка</label>
              <input
                value={caseData.link}
                onChange={(e) =>
                  setCaseData((prevCaseData) => ({
                    ...prevCaseData,
                    link: e.target.value,
                  }))
                }
              />
            </div>

            <div className="add_2">
              <label>Регистрационное имя</label>
              <input
                value={caseData.reg_name}
                onChange={(e) =>
                  setCaseData((prevCaseData) => ({
                    ...prevCaseData,
                    reg_name: e.target.value,
                  }))
                }
              />
            </div>

            <div className="add_2">
              <label>Мастер-поверитель АРШИН</label>
              <input
                value={caseData.arshin_name}
                onChange={(e) =>
                  setCaseData((prevCaseData) => ({
                    ...prevCaseData,
                    arshin_name: e.target.value,
                  }))
                }
              />
            </div>

            <div className="add_2">
              <label>Мастер-поверитель ФСА</label>
              <input
                value={caseData.fsa_name}
                onChange={(e) =>
                  setCaseData((prevCaseData) => ({
                    ...prevCaseData,
                    fsa_name: e.target.value,
                  }))
                }
              />
            </div>

            <div className="add_2">
              <label>СНИЛС</label>
              <input
                value={caseData.snils}
                onChange={(e) =>
                  setCaseData((prevCaseData) => ({
                    ...prevCaseData,
                    snils: e.target.value,
                  }))
                }
              />
            </div>

            <div className="selected">
              <label>Год</label>
              <div className="selected_box">
                <div
                  className="selected_users_top"
                  onClick={() => setYearOpen(!yearOpen)}
                >
                  {selectedYearName}
                  <span className="fas fa-angle-down"></span>
                </div>

                {yearOpen && (
                  <div className="selected_users_bottom">
                    {listYear.map((name, i) => (
                      <p key={i} onClick={() => onClickListYear(i)}>
                        {name}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className="selected">
              <label>Компания</label>
              <div className="selected_box">
                <div
                  className="selected_users_top"
                  onClick={() => setCompanyOpen(!companyOpen)}
                >
                  {selectedCompanyNme}
                  <span className="fas fa-angle-down"></span>
                </div>

                {companyOpen && (
                  <div className="selected_users_bottom">
                    {companies.map((company, id) => (
                      <p key={id} onClick={() => onClickListCompany(id)}>
                        {company.name}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </div>

            {kitDataList.slice(0, 5).map((kitData, index) => (
              <div key={index}>
                <h3>
                  Заполните данные для "
                  {kitData.device_type || `Устройство ${index + 1}`}"
                </h3>

                <div className="add_2">
                  <label>Порядковый номер устройства</label>
                  <input
                    value={kitData.device_number}
                    onChange={(e) => {
                      const updatedKitDataList = [...kitDataList];
                      updatedKitDataList[index] = {
                        ...kitDataList[index],
                        device_number: e.target.value,
                      };
                      setKitDataList(updatedKitDataList);
                    }}
                  />
                </div>

                <div className="add_2">
                  <label>Наименование устройства</label>
                  <input
                    value={kitData.device_type}
                    onChange={(e) => {
                      const updatedKitDataList = [...kitDataList];
                      updatedKitDataList[index] = {
                        ...kitDataList[index],
                        device_type: e.target.value,
                      };
                      setKitDataList(updatedKitDataList);
                    }}
                  />
                </div>

                <div className="add_2">
                  <label>Номер производителя устройства</label>
                  <input
                    value={kitData.device_manufacturer_number}
                    onChange={(e) => {
                      const updatedKitDataList = [...kitDataList];
                      updatedKitDataList[index] = {
                        ...kitDataList[index],
                        device_manufacturer_number: e.target.value,
                      };
                      setKitDataList(updatedKitDataList);
                    }}
                  />
                </div>

                <div className="add_2">
                  <label>Описание устройства</label>
                  <input
                    value={kitData.device_description}
                    onChange={(e) => {
                      const updatedKitDataList = [...kitDataList];
                      updatedKitDataList[index] = {
                        ...kitDataList[index],
                        device_description: e.target.value,
                      };
                      setKitDataList(updatedKitDataList);
                    }}
                  />
                </div>

                <div className="add_2">
                  <label>Подпись устройства</label>
                  <input
                    value={kitData.device_sign}
                    onChange={(e) => {
                      const updatedKitDataList = [...kitDataList];
                      updatedKitDataList[index] = {
                        ...kitDataList[index],
                        device_sign: e.target.value,
                      };
                      setKitDataList(updatedKitDataList);
                    }}
                  />
                </div>

                <button
                  className="cases_delete-btn"
                  onClick={() => handleDeleteDevice(index)}
                >
                  Удалить
                </button>
              </div>
            ))}

            <div className="cases-btn">
              <button className="add-btn" onClick={handleAddDevice}>
                Добавить устройство
              </button>

              <button className="button" onClick={handleCasesClick}>
                <FaSave className="button-icon" />
                <p>Сохранить</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCases;
