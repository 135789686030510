import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Protocol from "./Protocol/Protocol";
import Users from "./Users/Users";
import Meters from "./Meters/Meters";
import Cases from "./Cases/Cases";
import ShowUser from "./Users/ShowUser";
import EditUser from "./Users/EditUser";
import ShowProtocol from "./Protocol/ShowProtocol";
import EditProtocol from "./Protocol/EditProtocol";
import ShowDevice from "./Meters/ShowMeters";
import EditDevice from "./Meters/EditMeters";
import ShowCases from "./Cases/ShowCases";
import EditCases from "./Cases/EditCases";
import NewUsers from "./Users/NewUsers";
import NewMeters from "./Meters/NewMeters";
import NewCases from "./Cases/NewCases";
import NewProtocol from "./Protocol/NewProtocol";

const HomeRight = () => {
  return (
    <div>
      <div className="container__right">
        <Routes>
          <Route path="protocols" element={<Protocol />} />
          <Route path="protocols/:protocols_id" element={<ShowProtocol />} />
          <Route path="protocols/create" element={<NewProtocol />} />
          <Route
            path="protocols/:protocols_id/edit"
            element={<EditProtocol />}
          />

          <Route path="users" element={<Users />} />
          <Route path="users/:users_id" element={<ShowUser />} />
          <Route path="users/create" element={<NewUsers />} />
          <Route path="users/:users_id/edit" element={<EditUser />} />

          <Route path="meters" element={<Meters />} />
          <Route path="meters/:meters_id" element={<ShowDevice />} />
          <Route path="meters/create" element={<NewMeters />} />
          <Route path="meters/:meter_id/edit" element={<EditDevice />} />

          <Route path="cases" element={<Cases />} />
          <Route path="cases/:cases_id" element={<ShowCases />} />
          <Route path="cases/create" element={<NewCases />} />
          <Route path="cases/:cases_id/edit/" element={<EditCases />} />
        </Routes>
      </div>
    </div>
  );
};

export default HomeRight;
