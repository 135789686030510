import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { FaPlus, FaSearch, FaEdit } from "react-icons/fa";
import LoadingText from "../LoadingText.js";

const Cases = () => {
  const accessToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const location = useLocation();

  const [apiData, setApiData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [searchQuery, setSearchQuery] = useState("");
  const [userData, setUserData] = useState({
    role: role,
  });

  /*------ pop-up Компания ---------*/
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [companyData, setCompanyData] = useState([]);
  const [companyOpen, setCompanyOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const handleCompanyClick = async (company) => {
    setSelectedCompany(company);
    setSelectedCompanyId(company ? company.id : null);
    setCompanyOpen(false);

    // Сохраняем выбранную компанию в localStorage
    localStorage.setItem("selectedCompanyId", company ? company.id : "");

    // Сбросить текущую страницу на первую
    const newPage = 1;

    let queryParams = `/admin/cases?page=${newPage}`;
    if (company) {
      queryParams += `&company_id=${company.id}`;
    }

    navigate(queryParams);
  };

  const fetchCompanyData = async () => {
    try {
      const companyApiUrl = "https://app.metriva.ru/api/staff/companies/list";
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      };

      const companyResponse = await axios.get(companyApiUrl, { headers });

      setCompanyData(companyResponse.data.data);

      // Считываем сохраненное значение выбранной компании из localStorage
      const savedCompanyId = localStorage.getItem("selectedCompanyId");
      setSelectedCompanyId(savedCompanyId);

      // Устанавливаем имя выбранной компании на основе id
      const savedCompany = companyResponse.data.data.find(
        (company) => company.id === parseInt(savedCompanyId)
      );
      setSelectedCompany(savedCompany);
    } catch (error) {
      console.error(error);
    }
  };

  // Вызывайте fetchCompanyData внутри useEffect
  useEffect(() => {
    fetchCompanyData();
  }, []);

  const fetchData = async (page = 1) => {
    setIsLoading(true);
    try {
      const apiUrl = `https://app.metriva.ru/api/staff/cases?page=${page}`;
      const queryParams =
        role === "super" && selectedCompanyId
          ? `&company_id=${selectedCompanyId}`
          : "";
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      };
      const response = await axios.get(`${apiUrl}${queryParams}`, { headers });

      setApiData(response.data.data);

      setPagination({
        current_page: response.data.meta.current_page,
        last_page: response.data.meta.last_page,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedCompanyId]);

  useEffect(() => {
    const fetchAllData = async () => {
      setIsLoading(true);
      try {
        const apiUrl = `https://app.metriva.ru/api/staff/cases`;
        const queryParams = selectedCompanyId
          ? `?company_id=${selectedCompanyId}`
          : "";
        const headers = {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        };

        const response = await axios.get(`${apiUrl}${queryParams}`, {
          headers,
        });
        const filteredData = response.data.data.filter((item) => {
          const searchString = `${item.name} ${item.inner_name} ${item.reg_number} ${item.year} ${item.reg_name} ${item.status}`;
          return searchString.toLowerCase().includes(searchQuery.toLowerCase());
        });

        setApiData(filteredData);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllData();
  }, [searchQuery, selectedCompanyId]);

  const [pagination, setPagination] = useState({
    current_page: 1,
    last_page: 1,
  });

  const handlePageClick = (page) => {
    let queryParams = `?page=${page}`;

    if (selectedCompanyId) {
      queryParams += `&company_id=${selectedCompanyId}`;
    }

    navigate(`/admin/cases${queryParams}`);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = parseInt(params.get("page"), 10) || 1;
    if (!params.get("page")) {
      navigate(`/admin/cases?page=1`);
    } else {
      fetchData(page);
    }
  }, [location]);

  /*--------Кнопки Смотреть/Ред/Удалить------- */
  const handleViewClick = (casesId) => {
    const selectedData = apiData.find((item) => item.id === casesId);
    navigate(`/admin/cases/${casesId}`, { state: { data: selectedData } });
  };

  const handleViewEditClick = (casesId) => {
    navigate(`/admin/cases/${casesId}/edit`);
  };

  const handleCasesClick = () => {
    navigate("/admin/cases/create");
  };

  useEffect(() => {
    const storedRole = localStorage.getItem("role");
    if (role !== storedRole) {
      localStorage.setItem("role", storedRole);
      setUserData((prevUserData) => ({
        ...prevUserData,
        role: storedRole,
      }));
    }
  }, [role]);

  return (
    <div>
      <div className="header">
        <button onClick={handleCasesClick} className="create-button">
          <FaPlus className="create-icon" />
          Добавить
        </button>

        {role === "super" && (
          <div className="selected_meter">
            <div className="protocol_categories-box1">
              <div
                className="protocol_categories_top"
                onClick={() => setCompanyOpen(!companyOpen)}
              >
                <p>{selectedCompany ? selectedCompany.name : "Все компании"}</p>
                <span className="fas fa-angle-down"></span>
              </div>

              {companyOpen && (
                <div className="protocol_categories_bottom">
                  <p onClick={() => handleCompanyClick(null)}>Все компании</p>
                  {companyData.map((company) => (
                    <p
                      key={company.id}
                      onClick={() => handleCompanyClick(company)}
                    >
                      {company.name}
                    </p>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}

        <div className="search-container">
          <input
            type="text"
            className="search-input"
            placeholder="Поиск"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button className="search-button">
            <FaSearch className="search-icon" />
          </button>
        </div>
      </div>

      <div className="main">
        <main className="main-container">
          {isLoading ? (
            <LoadingText />
          ) : (
            <div className="attend">
              <div className="attendance">
                <div className="attendance-list">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Номер</th>
                        <th>Наименование</th>
                        <th>ФИФ</th>
                        <th>Год</th>
                        <th>Статус</th>
                        <th>Рег. имя</th>
                        <th>Мастер-поверитель ФСА</th>
                        <th>СНИЛС</th>
                        {role === "super" && <th>Компания</th>}
                        <th className="th">Просм.</th>
                        <th className="th">Ред.</th>
                      </tr>
                    </thead>
                    <tbody>
                      {apiData
                        .filter((item) => {
                          const searchString = `${item.inner_name} ${item.name} ${item.reg_number} ${item.year} ${item.reg_name} ${item.status}`;
                          return searchString
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase());
                        })
                        .map((item) => (
                          <tr key={item.id}>
                            <td>{item.inner_name}</td>
                            <td>{item.name}</td>
                            <td>{item.reg_number}</td>
                            <td>{item.year}</td>
                            <td>
                              {item.status === "active"
                                ? "Активный"
                                : item.status}
                            </td>
                            <td>{item.reg_name}</td>
                            <td>{item.fsa_name}</td>
                            <td>{item.snils}</td>
                            {role === "super" && <td>{item.company?.name}</td>}
                            <td
                              onClick={() => handleViewClick(item.id)}
                              style={{ cursor: "pointer" }}
                            >
                              <span className="fas fa-eye download-icon"></span>
                            </td>
                            <td
                              onClick={() => handleViewEditClick(item.id)}
                              style={{ cursor: "pointer" }}
                            >
                              <FaEdit className="download-icon" />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>

                  <div className="pagination">
                    {Array.from(
                      { length: pagination.last_page },
                      (_, i) => i + 1
                    ).map((page) => (
                      <button
                        key={page}
                        className={
                          pagination.current_page === page
                            ? "active_paginate"
                            : ""
                        }
                        onClick={() => handlePageClick(page)}
                      >
                        {page}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export default Cases;
