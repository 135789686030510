import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { FaSave } from "react-icons/fa";

const EditUsers = () => {
  const accessToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const { users_id } = useParams();
  const [userFormData, setUserFormData] = useState({});

  /*-------Pop-up для поля "должность"----- */
  const [roleOpen, setRoleOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState(0);
  const listRole = ["Администратор", "Метролог", "Руководитель"];

  const onClickListRole = (id) => {
    setSelectedRole(id);
    setRoleOpen(false);
    const selectedRoleValue = Object.values(roleTranslations)[id];
    setUserFormData((prevData) => ({
      ...prevData,
      role: selectedRoleValue,
    }));
  };

  const roleTranslations = {
    Администратор: "admin",
    Метролог: "metrologist",
    Руководитель: "brigadier",
  };

  const roleTranslations1 = {
    admin: "Администратор",
    metrologist: "Метролог",
    brigadier: "Руководитель",
  };

  /*-------Pop-up для поля "комплект Си"----- */
  const [poverkaOpen, setPoverkaOpen] = useState(false);
  const [selectedPoverka, setSelectedPoverka] = useState(0);
  const [poverka, setPoverka] = useState([]);
  const onClickListPoverka = (id) => {
    setSelectedPoverka(id);
    setSelectedPoverkaName(poverka[id]?.name || "");
    setUserFormData((prevData) => ({
      ...prevData,
      case_id: poverka[id]?.id || "",
    }));
    setPoverkaOpen(false);
  };
  const [selectedPoverkaName, setSelectedPoverkaName] = useState(
    userFormData.caseRelation?.name
  );

  /*------Получаем данные от бэкэнд в pop-up Поверки-------- */
  useEffect(() => {
    const fetchPoverka = async () => {
      try {
        const apiUrl = "https://app.metriva.ru/api/staff/cases/list";
        const headers = {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        };
        const response = await axios.get(apiUrl, { headers });
        const poverka = response.data.data;
        setPoverka(poverka);

        console.log(poverka);
      } catch (error) {
        console.error(error);
      }
    };

    fetchPoverka();
  }, []);

  /*-------- pop-up пользователя------- */
  const selectedRoleName = listRole[selectedRole];

  const [selectedMetrologUsers, setSelectedMetrologUsers] = useState([]);
  const [usersOpen, setUsersOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState(null);
  const [users, setUsers] = useState([]);
  const onClickListUsers = (id) => {
    if (selectedRoleName === "Руководитель") {
      setUserFormData((prevData) => {
        const isSelected = prevData.metrologists.some((user) => user.id === id);
        const updatedMetrologists = isSelected
          ? prevData.metrologists.filter((user) => user.id !== id)
          : [...prevData.metrologists, users.find((user) => user.id === id)];
        return {
          ...prevData,
          metrologists: updatedMetrologists,
        };
      });
    } else if (selectedRoleName === "Метролог") {
      setUserFormData((prevData) => ({
        ...prevData,
        brigadier_id: id,
      }));
    }

    setSelectedUsers(id);
    setUsersOpen(false);

    const selectedUserName = users.find((user) => user.id === id)?.name || "";
    setUserFormData((prevData) => ({
      ...prevData,
      brigadier_id: id,
      metrologists: [
        ...prevData.metrologists.filter((user) => user.id !== id),
        { id, name: selectedUserName },
      ],
    }));
  };

  const selectedUsersName = users[selectedUsers]?.name;

  /*------Получаем данные от бэкэнд в pop-up Пользователя */
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const apiUrl = "https://app.metriva.ru/api/staff/users/list";
        const headers = {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        };
        const response = await axios.get(apiUrl, { headers });
        const users = response.data.data;
        setUsers(users);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUsers();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "role" && value === "metrologist") {
      setUserFormData((prevData) => ({
        ...prevData,
        brigadier_id: null,
      }));
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const apiUrl = `https://app.metriva.ru/api/staff/users/${users_id}/edit`;
        const headers = {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        };

        const response = await axios.get(apiUrl, { headers });
        const userData = response.data.data;

        setUserFormData(userData);

        setSelectedPoverkaName(userData.caseRelation?.name || "");
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserData();
  }, [users_id]);

  /*-------Запрос put на бэкэнд----- */
  const handleClick = async () => {
    try {
      const apiUrl = `https://app.metriva.ru/api/staff/users/${users_id}`;
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      };

      const response = await axios.put(apiUrl, userFormData, { headers });

      alert("Пользователь отредактирован.");

      navigate("/admin/users");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className="add">
        <div className="add_1">
          <h2>Редактирование пользователя</h2>

          <div className="add__container">
            <div className="add_2">
              <label>Имя в системе:</label>
              <input
                type="text"
                placeholder=""
                name="name"
                value={userFormData.name || ""}
                onChange={handleChange}
              />
            </div>

            {/* <div className="add_2">
              <label>Имя в ФГИС ФСА:</label>
              <input
                type="text"
                placeholder=""
                name="fsa_name"
                value={userFormData.fsa_name || ""}
                onChange={handleChange}
              />
            </div>

            <div className="add_2">
              <label>СНИЛС:</label>
              <input
                type="text"
                placeholder=""
                name="snils"
                value={userFormData.snils || ""}
                onChange={handleChange}
              />
            </div> */}

            <div className="add_2">
              <label>Логин:</label>
              <input
                type="text"
                placeholder=""
                name="login"
                value={userFormData.login || ""}
                onChange={handleChange}
              />
            </div>

            <div className="add_2">
              <label>Пароль:</label>
              <input
                type="text"
                placeholder="Если не меняете пароль, оставьте поле пустым"
                name="password"
                value={userFormData.password || ""}
                onChange={handleChange}
              />
            </div>

            <div className="selected">
              <label>Должность</label>
              <div className="selected_box">
                <div
                  className="selected_users_top"
                  onClick={() => setRoleOpen(!roleOpen)}
                >
                  {roleTranslations1[userFormData.role] || ""}
                  <span className="fas fa-angle-down"></span>
                </div>

                {roleOpen && (
                  <div className="selected_users_bottom">
                    {listRole.map((name, id, i) => (
                      <p
                        key={id}
                        onClick={() => onClickListRole(id)}
                        className={selectedRole === i ? "active" : ""}
                      >
                        {name}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </div>

            {(userFormData.role === "metrologist" ||
              userFormData.role === "brigadier") && (
              <div className="selected">
                <label>
                  {userFormData.role === "metrologist"
                    ? "Руководитель"
                    : "Сотрудники"}
                </label>
                <div className="selected_box">
                  <div
                    className="selected_users_top"
                    onClick={() => setUsersOpen(!usersOpen)}
                  >
                    {userFormData.role === "metrologist"
                      ? userFormData.brigadier_id
                        ? users.find(
                            (user) => user.id === userFormData.brigadier_id
                          )?.name
                        : ""
                      : userFormData.metrologists
                          ?.map(
                            (user) => users.find((u) => u.id === user.id)?.name
                          )
                          .join(", ") || ""}

                    {roleTranslations1[userFormData.role] !==
                      "Руководитель" && (
                      <span
                        className="fas fa-angle-down"
                        style={{ marginLeft: "auto" }}
                      ></span>
                    )}
                  </div>

                  {usersOpen && userFormData.role === "metrologist" && (
                    <div className="selected_users_bottom">
                      {users
                        .filter((user) => user.role === "brigadier")
                        .map((user, id) => (
                          <p
                            key={id}
                            onClick={() => onClickListUsers(user.id)}
                            className={
                              selectedUsers === user.id ? "active-users" : ""
                            }
                          >
                            {user.name}
                          </p>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className="selected">
              <label>Комплект СИ:</label>
              <div className="selected_box">
                <div
                  className="selected_users_top"
                  onClick={() => setPoverkaOpen(!poverkaOpen)}
                >
                  {userFormData.case_id}
                  <span
                    className="fas fa-angle-down"
                    style={{ marginLeft: "auto" }}
                  ></span>
                </div>

                {poverkaOpen && (
                  <div className="selected_users_bottom_cases">
                    {poverka.map((poverka, id, i) => (
                      <p
                        key={id}
                        onClick={() => onClickListPoverka(id)}
                        className={selectedPoverka === i ? "active" : ""}
                      >
                        {poverka.name}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className="add_2">
              <label>Email:</label>
              <input
                type="text"
                placeholder=""
                name="email"
                value={userFormData.email || ""}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="btn_meters">
            <button className="button" onClick={handleClick}>
              <FaSave className="button-icon" />
              <p>Сохранить</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUsers;
