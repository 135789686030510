import React, { useState, useEffect } from "react";

const Inspection = ({ areBothChecked }) => {
  const [isChecked, setIsChecked] = useState(areBothChecked);

  useEffect(() => {
    setIsChecked(areBothChecked);
  }, [areBothChecked]);

  return (
    <div className="inspection">
      <div className="inspection__text">
        <h2>Заключение:</h2>
        <p>На основании результатов поверки, средство измерения признано:</p>
      </div>
      <div className="inspection__items">
        {areBothChecked ? (
          <label>
            <input type="checkbox" checked={isChecked} readOnly />
            <p>Пригодным к применению.</p>
          </label>
        ) : (
          <p className="inspection__items-non">
            *Средство измерения признано не пригодным к применению!
          </p>
        )}
      </div>
    </div>
  );
};

export default Inspection;
