import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

const ShowProtocol = () => {
  const location = useLocation();
  const { data, apiData } = location.state || {};
  const [protocolData, setProtocolData] = useState(null);
  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    // Проверяем, доступны ли уже данные
    if (data) {
      setProtocolData(data);
    } else {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `https://app.metriva.ru/api/staff/protocols/`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
              },
            }
          );
          setProtocolData(response.data);
        } catch (error) {
          console.error(error);
        }
      };

      fetchData();
    }
  }, []);

  return (
    <div>
      <div className="main">
        <div className="meter__container">
          <div className="protocol_box">
            <h4>ПРОТОКОЛ ПЕРИОДИЧЕСКОЙ ПОВЕРКИ №{protocolData?.id}</h4>
            <div className="protocol_comlplect">
              <p className="protocol_comlplect-p">Комплект средств измерений</p>
              <p>{protocolData?.caseRelation?.inner_name}</p>
            </div>

            <div className="protocol_counter">
              <p>Поверяемый счётчик</p>
              <div className="protocol_counter_complect">
                <div className="protocol_counter_complect-box-1">
                  <p className="protocol_comlplect-p">Номер в реестре (ФИФ):</p>
                  <p className="protocol_counter-p">
                    {protocolData?.meter?.number_registry}
                  </p>
                </div>

                <div className="protocol_counter_complect-box-2">
                  <p className="protocol_comlplect-p">Наименование счётчика:</p>
                  <p className="protocol_counter-p">
                    {protocolData?.meter?.name_si}
                  </p>
                </div>

                <div className="protocol_counter_complect-box">
                  <p className="protocol_comlplect-p">Год выпуска:</p>
                  <p className="protocol_counter-p">
                    {protocolData?.meter_factory_year}
                  </p>
                </div>

                <div className="protocol_counter_complect-box">
                  <p className="protocol_comlplect-p">Измеряемая вода:</p>
                  <p className="protocol_counter-p">
                    {protocolData?.meter_type === "cold"
                      ? "Холодная"
                      : protocolData?.meter_type === "hot"
                      ? "Горячая"
                      : ""}
                  </p>
                </div>
              </div>

              <div className="protocol_counter-num">
                <p className="protocol_comlplect-p">Заводской номер:</p>
                <p className="protocol_counter-p">
                  {protocolData?.meter_factory_number}
                </p>
              </div>
            </div>

            <div className="protocol_adress">
              <div className="protocol_adress-name">
                <p className="protocol_comlplect-p">ФИО заказчика:</p>
                <div className="protocol_adress-name-box">
                  <p className="protocol_adress-name-box-p">
                    {protocolData?.owner_name}
                  </p>
                  <p className="protocol_adress-name-box-p">
                    {protocolData?.owner_initial_strict}
                  </p>
                  <p className="protocol_adress-name-box-p">
                    {protocolData?.owner_initial_optional}
                  </p>
                </div>
              </div>

              <div className="protocol_adress-name">
                <p className="protocol_adress-p">Адрес проведения поверки:</p>
                <div className="protocol_adress-box-1">
                  <p>{protocolData?.owner_address}</p>
                </div>
              </div>
            </div>

            <div className="inspection">
              <div className="inspection__text">
                <h2>Внешний осмотр</h2>
                <p>
                  Раздел 2 «Методика поверки «Pr»: есть возможность визуально
                  считывать показания счётчика, не нарушена целостность счётного
                  механизма счетчика, отсутствуют механические повреждения на
                  корпусе счётчика, комплектность счётчика соответствует
                  описанию типа, знак утверждения типа нанесён на счётчик в
                  установленном виде.
                </p>
              </div>
              <div className="inspection__items">
                <label>
                  <input
                    type="checkbox"
                    readOnly
                    checked={protocolData?.mechanical_accepted || false}
                  />
                  <p>
                    Соответствует пункту 2.7.1 Методики поверки МИ 1592-2015
                  </p>
                </label>
              </div>
            </div>

            <div className="inspection">
              <div className="inspection__text">
                <h2>Опробование</h2>
                <p>
                  Раздел 2 «Методика поверки «Pr»: после выдержки в течение 5
                  минут в местах соединений и на корпусах счётчиков и эталона не
                  наблюдается каплевыделений и течи воды.
                </p>
              </div>
              <div className="inspection__items">
                <label>
                  <input
                    type="checkbox"
                    readOnly
                    checked={protocolData?.tightness_accepted || false}
                  />
                  <p>
                    Соответствует пункту 2.7.1 Методики поверки МИ 1592-2015
                  </p>
                </label>
              </div>
            </div>

            <div className="protocol_examination-box">
              <p className="protocol_testimony-title">Условия поверки</p>
              <div className="protocol_examination-box-inner">
                <div className="protocol_examination-box-inner-left">
                  <p>На начало поверки:</p>
                  <div className="box_inner-1">
                    <p className="protocol_testimony-inner-box-p">
                      Температура окружающей среды, °C
                    </p>
                    <p className="box_inner-p">
                      {protocolData?.conditions[0].air_temp}
                    </p>
                  </div>
                  <div className="box_inner-1">
                    <p className="protocol_testimony-inner-box-p">
                      Относительная влажность, %
                    </p>
                    <p className="box_inner-p">
                      {protocolData?.conditions[0].humidity}
                    </p>
                  </div>
                  <div className="box_inner-1">
                    <p className="protocol_testimony-inner-box-p">
                      Атмосферное давление, кПа
                    </p>
                    <p className="box_inner-p">
                      {protocolData?.conditions[0].pressure}
                    </p>
                  </div>
                  <div className="box_inner-1">
                    <p className="protocol_testimony-inner-box-p">
                      Температура воды, °C
                    </p>
                    <p className="box_inner-p">
                      {protocolData?.conditions[0].water_temp}
                    </p>
                  </div>
                </div>
                <div className="protocol_examination-box-inner-right">
                  <p>По окончании поверки:</p>
                  <div className="box_inner-1">
                    <p className="protocol_testimony-inner-box-p">
                      Температура окружающей среды, °C
                    </p>
                    <p className="box_inner-p">
                      {protocolData?.conditions[1].air_temp}
                    </p>
                  </div>
                  <div className="box_inner-1">
                    <p className="protocol_testimony-inner-box-p">
                      Относительная влажность, %
                    </p>
                    <p className="box_inner-p">
                      {protocolData?.conditions[1].humidity}
                    </p>
                  </div>
                  <div className="box_inner-1">
                    <p className="protocol_testimony-inner-box-p">
                      Атмосферное давление, кПа
                    </p>
                    <p className="box_inner-p">
                      {protocolData?.conditions[1].pressure}
                    </p>
                  </div>
                  <div className="box_inner-1">
                    <p className="protocol_testimony-inner-box-p">
                      Температура воды, °C
                    </p>
                    <p className="box_inner-p">
                      {protocolData?.conditions[1].water_temp}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="inspection">
              <div className="inspection__text">
                <h2>Заключение:</h2>
                <p>
                  На основании результатов поверки, средство измерения признано:
                </p>
              </div>
              <div className="inspection__items">
                <label>
                  <input
                    type="checkbox"
                    readOnly
                    checked={protocolData?.total_accepted || false}
                  />
                  <p>Пригодным к применению.</p>
                </label>
              </div>
            </div>

            <div className="protocol-date">
              <div className="protocol-date-box">
                <div>
                  <p className="protocol-date-box-title">Дата поверки:</p>
                  <p className="protocol_adress-name-box-p">
                    {protocolData?.verification_date}
                  </p>
                </div>

                <div>
                  <p className="protocol-date-box-title">
                    Дата следующей поверки:
                  </p>
                  <p className="protocol_adress-name-box-p">
                    {protocolData?.next_verification_date}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowProtocol;
