import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { FaSave, FaCheck } from "react-icons/fa";

import Counter from "./EditProtocol/EditCounter";
import Name from "./EditProtocol/EditName";
import Address from "./EditProtocol/EditAddress";
import BlockTick from "./EditProtocol/EditBlockTick";
import Testimony from "./EditProtocol/EditTestimony";
import Examination from "./EditProtocol/EditExamination";
import Inspection from "./EditProtocol/EditInspection";
import DateComponent from "./EditProtocol/EditDate";

import LoadingText from "../LoadingText.js";

const Edit = () => {
  const accessToken = localStorage.getItem("accessToken");

  const navigate = useNavigate();
  const { protocols_id } = useParams();
  const [protocolFormData, setProtocolFormData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  /*-----Время в условия поверки------ */
  const currentTime = new Date();
  const year = currentTime.getFullYear();
  const month = String(currentTime.getMonth() + 1).padStart(2, "0");
  const day = String(currentTime.getDate()).padStart(2, "0");
  const hours = String(currentTime.getHours()).padStart(2, "0");
  const minutes = String(currentTime.getMinutes()).padStart(2, "0");
  const seconds = String(currentTime.getSeconds()).padStart(2, "0");

  const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  /*-------Меняем время в conditions */
  const getRandomTime = (currentTime) => {
    const randomOffsetMinutes = Math.floor(Math.random() * 11) + 40; // Генерируем случайное число от 40 до 50 для минут
    const randomOffsetSeconds = Math.floor(Math.random() * 60); // Генерируем случайное число от 0 до 59 для секунд

    const offsetMilliseconds =
      (randomOffsetMinutes * 60 + randomOffsetSeconds) * 1000; // Переводим минуты и секунды в миллисекунды
    const randomTime = new Date(
      currentTime.getTime() - offsetMilliseconds
    ).toLocaleTimeString();
    return randomTime;
  };

  const firstObjectTime = getRandomTime(currentTime);
  const secondObjectTime = currentTime.toLocaleTimeString();

  /*-----Счетчик------ */
  const [meterFactoryNumber, setMeterFactoryNumber] = useState("");
  const [meterFactoryYear, setMeterFactoryYear] = useState("");
  const [selectedWater, setSelectedWater] = useState("cold");
  const [selectedRegistryValue, setSelectedRegistryValue] = useState("");
  const [selectedRegistryId, setSelectedRegistryId] = useState("");
  const [selectedRegistryHot, setSelectedRegistryHot] = useState("");
  const [selectedRegistryCold, setSelectedRegistryCold] = useState("");

  /*-----Имя/Адрес------ */
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [ownerInitialOptional, setOwnerInitialOptional] = useState("");
  const [ownerAddress, setOwnerAddress] = useState("");

  /*-----Дата поверки/дата следующей поверки------ */

  const [verificationDateFormatted, setVerificationDateFormatted] =
    useState("");
  const [nextVerificationDateFormatted, setNextVerificationDateFormatted] =
    useState("");

  /*-----Определение относительной погрешности------ */
  const [start, setStart] = useState("");
  const [finish, setFinish] = useState("");
  const [reference, setReference] = useState((0.01).toFixed(3));
  const [volumeDifference, setVolumeDifference] = useState("0.000");
  const [formattedRelativeError, setFormattedRelativeError] = useState("0.00");
  const [blockColor, setBlockColor] = useState("3px solid #ee6969");
  const [start1, setStart1] = useState("");
  const [finish1, setFinish1] = useState("");
  const [reference1, setReference1] = useState((0.02).toFixed(3));
  const [volumeDifference1, setVolumeDifference1] = useState("0.000");
  const [formattedRelativeError1, setFormattedRelativeError1] =
    useState("0.00");
  const [blockColor1, setBlockColor1] = useState("3px solid #ee6969");
  const [start2, setStart2] = useState("");
  const [finish2, setFinish2] = useState("");
  const [consumption, setConsumption] = useState("");
  const [reference2, setReference2] = useState((0.1).toFixed(3));
  const [volumeDifference2, setVolumeDifference2] = useState("0.000");
  const [formattedRelativeError2, setFormattedRelativeError2] =
    useState("0.00");
  const [blockColor2, setBlockColor2] = useState("3px solid #ee6969");

  /*-----Счетчик------ */
  const handleMeterFactoryNumber = (meterFactoryNumber) => {
    setMeterFactoryNumber(meterFactoryNumber);
  };
  const handleMeterFactoryYearChange = (year) => {
    setMeterFactoryYear(year);
  };
  const handleWaterSelection = (waterType) => {
    setSelectedWater(waterType);
  };

  const handleSaveRegistrySelection = (id, cold, hot, number_registry) => {
    setSelectedRegistryValue(number_registry);
    setSelectedRegistryId(id);
    setSelectedRegistryHot(hot);
    setSelectedRegistryCold(cold);
  };

  /*-----Определение относительной погрешности------ */
  const handleSaveTestimony = (testimonyData) => {
    const {
      start,
      finish,
      start1,
      finish1,
      start2,
      finish2,
      consumption,
      reference,
      volumeDifference,
      formattedRelativeError,
      blockColor,
      reference1,
      volumeDifference1,
      formattedRelativeError1,
      blockColor1,
      reference2,
      volumeDifference2,
      formattedRelativeError2,
      blockColor2,
    } = testimonyData;

    setStart(start);
    setFinish(finish);
    setStart1(start1);
    setFinish1(finish1);
    setStart2(start2);
    setFinish2(finish2);
    setConsumption(consumption);
    setReference(reference);
    setVolumeDifference(volumeDifference);
    setFormattedRelativeError(formattedRelativeError);
    setBlockColor(blockColor);

    setReference1(reference1);
    setVolumeDifference1(volumeDifference1);
    setFormattedRelativeError1(formattedRelativeError1);
    setBlockColor1(blockColor1);
    setReference2(reference2);
    setVolumeDifference2(volumeDifference2);
    setFormattedRelativeError2(formattedRelativeError2);
    setBlockColor2(blockColor2);
  };

  /*-----Имя/Адрес------ */
  const handleSaveAddress = (address) => {
    setOwnerAddress(address);
  };
  const handleSaveName = (last, first, ownerInitialOptional) => {
    setLastName(last);
    setFirstName(first);
    setOwnerInitialOptional(ownerInitialOptional);
  };

  /*---------Внешний осмотр----- */
  const [isOut, setIsOut] = useState(false);
  const [isTesting, setIsTesting] = useState(false);

  const handleOutShowBox = (isOut) => {
    setIsOut(isOut);
  };

  const handleTesting = (isTesting) => {
    setIsTesting(isTesting);
  };

  /*-----Заключение------ */
  const [areBothChecked, setAreBothChecked] = useState(false);

  useEffect(() => {
    // Проверяем, выполняются ли все необходимые условия
    const areConditionsMet =
      isOut &&
      isTesting &&
      blockColor === "3px solid #2897bf" &&
      blockColor1 === "3px solid #2897bf" &&
      blockColor2 === "3px solid #2897bf";

    // Обновляем значение areBothChecked на основе условий

    setAreBothChecked(areConditionsMet);
  }, [isOut, isTesting, blockColor, blockColor1, blockColor2]);

  /*-----Дата поверки/дата следующей поверки------ */
  const handleDateChange = (verificationDate, nextVerificationDate) => {
    setVerificationDateFormatted(verificationDate);
    setNextVerificationDateFormatted(nextVerificationDate);
  };

  /*----conditions----- */
  const [examinationData, setExaminationData] = useState({
    initialConditions: {},
    initialConditions1: {},
  });

  const handleSaveConditions = (data) => {
    setExaminationData(data);
  };

  /*-----Подтвержденные/не подтвержденные--- */
  const [selectedStatus, setSelectedStatus] = useState("ready");
  const [confirm, setConfirm] = useState(false);
  const [selectedConfirm, setSelectedConfirm] = useState(0);
  const [SelectedConfirmName, setSelectedConfirmName] = useState("");
  const listConfirm = ["Не подтвержденный", "Подтвержденный"];
  const onClickListConfirm = (i) => {
    setSelectedConfirm(i);
    setConfirm(false);

    if (i === 0) {
      setSelectedStatus("ready");
      setSelectedConfirmName("Не подтвержденный");
    } else if (i === 1) {
      setSelectedStatus("checked");
      setSelectedConfirmName("Подтвержденный");
    }
  };

  /*-----получаем данные от бэкэнд(первоначальное состояние)---- */
  useEffect(() => {
    const fetchProtocolData = async () => {
      setIsLoading(true);
      try {
        const headers = {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        };

        const response = await axios.get(
          `https://app.metriva.ru/api/staff/protocols/${protocols_id}/edit`,
          { headers }
        );

        const protocolData = response.data.data;
        setProtocolFormData(protocolData);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProtocolData();
  }, [protocols_id]);

  useEffect(() => {
    if (Object.keys(protocolFormData).length > 0) {
      console.log("protocolData:", protocolFormData);
    }
  }, [protocolFormData]);

  /*------отправляем отредактированные данные на бэкэнд------ */
  const handleClick = async () => {
    try {
      const apiUrl = `https://app.metriva.ru/api/staff/protocols/${protocols_id}`;
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      };

      const data = {
        status: "checked",

        meter_factory_number: meterFactoryNumber,
        meter_factory_year: meterFactoryYear,
        meter_type: selectedWater,

        owner_name: lastName,
        owner_initial_strict: firstName,
        owner_initial_optional: ownerInitialOptional,
        owner_address: ownerAddress,

        mechanical_accepted: isOut,
        tightness_accepted: isTesting,
        total_accepted: areBothChecked,

        verification_date:
          verificationDateFormatted || protocolFormData.verification_date,
        next_verification_date:
          nextVerificationDateFormatted ||
          protocolFormData.next_verification_date,

        accepted_at: formattedDateTime,

        meter_id: selectedRegistryId || protocolFormData?.meter?.id,
        case_id: protocolFormData?.caseRelation?.id,

        conditions: [
          {
            id: protocolFormData?.conditions[0]?.id,
            air_temp: parseFloat(examinationData.initialConditions.air_temp),
            water_temp: parseFloat(
              examinationData.initialConditions.water_temp
            ),
            humidity: parseFloat(examinationData.initialConditions.humidity),
            pressure: parseFloat(examinationData.initialConditions.pressure),
            time: firstObjectTime,
          },
          {
            id: protocolFormData?.conditions[1]?.id,
            air_temp: parseFloat(examinationData.initialConditions1.air_temp),
            water_temp: parseFloat(
              examinationData.initialConditions1.water_temp
            ),
            humidity: parseFloat(examinationData.initialConditions1.humidity),
            pressure: parseFloat(examinationData.initialConditions1.pressure),
            time: secondObjectTime,
          },
        ],

        indications: [
          {
            id: protocolFormData?.indications[0]?.id,
            number: 5,
            indication_number: parseFloat(volumeDifference),
            permissible_error: parseFloat(formattedRelativeError),
            consumption: 0.3,
            start: parseFloat(start),
            finish: parseFloat(finish),
            reference: parseFloat(reference),
          },
          {
            id: protocolFormData?.indications[1]?.id,
            number: 2,
            indication_number: parseFloat(volumeDifference1),
            permissible_error: parseFloat(formattedRelativeError1),
            consumption: 0.132,
            start: parseFloat(start1),
            finish: parseFloat(finish1),
            reference: parseFloat(reference1),
          },
          {
            id: protocolFormData?.indications[2]?.id,
            number: 2,
            indication_number: parseFloat(volumeDifference2),
            permissible_error: parseFloat(formattedRelativeError2),
            consumption: parseFloat(consumption),
            start: parseFloat(start2),
            finish: parseFloat(finish2),
            reference: parseFloat(reference2),
          },
        ],
      };

      const response = await axios.put(apiUrl, data, { headers });

      alert("Протокол успешно отредактирован .");

      navigate("/admin/protocols");
    } catch (error) {
      console.error(error);
      alert(
        "Статус протокола уже подтверждён. Назад вернуть нельзя (в статус Неподтверждён)."
      );
    }
  };

  useEffect(() => {
    setSelectedStatus(protocolFormData.status || "ready");
    setSelectedConfirmName(
      protocolFormData.status === "ready"
        ? "Не подтвержденный"
        : "Подтвержденный"
    );
  }, [protocolFormData.status]);

  return (
    <main className="main-container">
      {isLoading ? (
        <LoadingText />
      ) : (
        <div className="protocol_box-edit">
          <div className="edit_top">
            <h4>ПРОТОКОЛ ПЕРИОДИЧЕСКОЙ ПОВЕРКИ № {protocolFormData.id}</h4>
          </div>

          <Counter
            setSelectedWater={setSelectedWater}
            selectedWater={selectedWater}
            onMeterFactoryNumber={handleMeterFactoryNumber}
            onMeterFactoryYearChange={handleMeterFactoryYearChange}
            onMeterFactoryWater={handleWaterSelection}
            onSaveRegistrySelection={handleSaveRegistrySelection}
            selectedRegistryId={selectedRegistryId}
          />

          <Name onSaveName={handleSaveName} />
          <Address onSaveAddress={handleSaveAddress} />
          <BlockTick
            onOutShowBox={handleOutShowBox}
            onTestingBox={handleTesting}
          />
          <Testimony onSaveTestimony={handleSaveTestimony} />
          <Examination onSaveConditions={handleSaveConditions} />
          <Inspection areBothChecked={areBothChecked} />
          <DateComponent
            selectedWater={selectedWater}
            selectedRegistryId={selectedRegistryId}
            selectedRegistryHot={selectedRegistryHot}
            selectedRegistryCold={selectedRegistryCold}
            onSaveDate={handleDateChange}
          />

          <div className="btn-edit">
            <button className="button" onClick={handleClick}>
              <FaSave className="button-icon" />
              <p>Сохранить</p>
            </button>
          </div>
        </div>
      )}
    </main>
  );
};

export default Edit;
