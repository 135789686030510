import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FaSave } from "react-icons/fa";

const NewUsers = () => {
  const accessToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const capitalizeEachWord = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  /*-------- pop-up должность --------- */
  const roleMapping = {
    Метролог: "metrologist",
    Руководитель: "brigadier",
    Администратор: "admin",
  };
  const listRole = Object.keys(roleMapping);
  const [roleOpen, setRoleOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState(0);
  const onClickListRole = (id) => {
    setSelectedRole(id);
    setRoleOpen(false);

    // Сбросить состояние selectedUsers при выборе роли "Руководитель"
    if (listRole[id] === "Руководитель") {
      setSelectedUsers(null);
    }
  };
  const selectedRoleName = listRole[selectedRole];
  const selectedRoleValue = roleMapping[selectedRoleName];

  /*-------- pop-up поверка------- */
  const [poverkaOpen, setPoverkaOpen] = useState(false);
  const [selectedPoverka, setSelectedPoverka] = useState(0);
  const [poverka, setPoverka] = useState([]);
  const onClickListPoverka = (id) => {
    setSelectedPoverka(id);
    setPoverkaOpen(false);
  };
  const selectedPoverkaNme = poverka[selectedPoverka]?.name;

  /*-------- pop-up пользователя------- */
  const [selectedMetrologUsers, setSelectedMetrologUsers] = useState([]);
  const [usersOpen, setUsersOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState(null);
  const [users, setUsers] = useState([]);
  const onClickListUsers = (id) => {
    if (selectedRoleName === "Руководитель") {
      setSelectedMetrologUsers((prevSelectedUsers) => {
        const isSelected = prevSelectedUsers.includes(id);
        if (isSelected) {
          return prevSelectedUsers.filter((userId) => userId !== id);
        } else {
          return [...prevSelectedUsers, id];
        }
      });
    } else {
      setSelectedUsers((prevSelectedUser) =>
        prevSelectedUser === id ? null : id
      );
      setUsersOpen(false);
    }
  };

  const selectedUsersName = users[selectedUsers]?.name;

  /* данные POST */

  const [userData, setUserData] = useState({
    name: "",
    login: "",
    password: "",
    case_id: "",
    company_id: "",
    role: "",
    email: "",
    // snils: "",
    // fsa_name: "",
  });

  const handleCasesClick = async () => {
    try {
      const apiUrl = "https://app.metriva.ru/api/staff/users";
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      };

      const updatedUserData = {
        ...userData,

        case_id: poverka[selectedPoverka]?.id,
        email: userData.email,
        login: userData.login,
        name: userData.name,
        // fsa_name: userData.fsa_name,
        // snils: userData.snils,
        password: userData.password,
        role: selectedRoleValue,
      };

      if (selectedRoleName === "Метролог" && selectedUsers !== null) {
        updatedUserData.brigadier_id = users[selectedUsers]?.id;
        delete updatedUserData.metrologist_ids;
      } else if (
        selectedRoleName === "Руководитель" &&
        selectedMetrologUsers.length > 0
      ) {
        updatedUserData.metrologist_ids = selectedMetrologUsers.map(
          (userId) => users[userId]?.id
        );
        delete updatedUserData.brigadier_id;
      } else {
        delete updatedUserData.brigadier_id;
        delete updatedUserData.metrologist_ids;
      }

      const response = await axios.post(apiUrl, updatedUserData, { headers });

      /*---Очистка формы после отправки данных--- */
      setUserData({
        name: "",
        login: "",
        password: "",
        case_id: "",
        company_id: "",
        role: "",
        email: "",
        // snils: "",
        // fsa_name: "",
      });

      alert("Пользователь создан");
      navigate("/admin/users");
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const anyFieldFilled = Object.values(userData).some(
          (value) => value.trim() !== ""
        );

        if (anyFieldFilled) {
          let errorMessage = "";

          if (!userData.name.trim()) {
            errorMessage += 'Поле "Имя" обязательно для заполнения.\n';
          }
          if (!userData.login.trim()) {
            errorMessage += 'Поле "Логин" обязательно для заполнения.\n';
          }
          if (!userData.password.trim()) {
            errorMessage += 'Поле "Пароль" обязательно для заполнения.\n';
          }
          if (!userData.email.trim()) {
            errorMessage += 'Поле "Email" обязательно для заполнения.\n';
          }

          alert(errorMessage);
        } else {
          // Если ни одно поле не заполнено, показываем общее сообщение
          alert("Все поля обязательны для заполнения\n");
        }
      } else {
        console.error(error);
        alert("Ошибка на сервере!");
      }
    }
  };

  /*------Получаем данные от бэкэнд в pop-up Пользователя */
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const apiUrl = "https://app.metriva.ru/api/staff/users/list";
        const headers = {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        };
        const response = await axios.get(apiUrl, { headers });
        const users = response.data.data;
        setUsers(users);
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUsers();
  }, []);

  /*------Получаем данные от бэкэнд в pop-up Поверки */
  useEffect(() => {
    const fetchPoverka = async () => {
      try {
        const apiUrl = "https://app.metriva.ru/api/staff/cases/list";
        const headers = {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        };
        const response = await axios.get(apiUrl, { headers });
        const poverka = response.data.data;
        setPoverka(poverka);
      } catch (error) {
        console.error(error);
      }
    };

    fetchPoverka();
  }, []);

  return (
    <div>
      <div className="add">
        <div className="add_1">
          <h2>Добавить нового пользователя</h2>

          <div className="add__container">
            <div className="add_2">
              <label>Имя в системе</label>
              <input
                value={userData.name}
                onChange={(e) =>
                  setUserData((prevUserData) => ({
                    ...prevUserData,
                    name: capitalizeEachWord(e.target.value),
                  }))
                }
              />
            </div>

            {/* <div className="add_2">
              <label>Имя в ФГИС ФСА</label>
              <input
                value={userData.fsa_name}
                onChange={(e) =>
                  setUserData((prevUserData) => ({
                    ...prevUserData,
                    fsa_name: capitalizeEachWord(e.target.value),
                  }))
                }
              />
            </div>

            <div className="add_2">
              <label>СНИЛС</label>
              <input
                value={userData.snils}
                onChange={(e) =>
                  setUserData((prevUserData) => ({
                    ...prevUserData,
                    snils: capitalizeEachWord(e.target.value),
                  }))
                }
              />
            </div> */}

            <div className="add_2">
              <label>Логин</label>
              <input
                value={userData.login}
                onChange={(e) =>
                  setUserData((prevUserData) => ({
                    ...prevUserData,
                    login: e.target.value,
                  }))
                }
              />
            </div>

            <div className="add_2">
              <label>Пароль</label>
              <input
                placeholder="Придумайте пароль от 6 до 15 символов"
                value={userData.password}
                onChange={(e) =>
                  setUserData((prevUserData) => ({
                    ...prevUserData,
                    password: e.target.value,
                  }))
                }
              />
            </div>

            <div className="add_2">
              <label>Email:</label>
              <input
                value={userData.email}
                onChange={(e) =>
                  setUserData((prevUserData) => ({
                    ...prevUserData,
                    email: e.target.value,
                  }))
                }
              />
            </div>

            <div className="selected">
              <label>Должность</label>
              <div className="selected_box">
                <div
                  className="selected_users_top"
                  onClick={() => setRoleOpen(!roleOpen)}
                >
                  {selectedRoleName}
                  <span className="fas fa-angle-down"></span>
                </div>

                {roleOpen && (
                  <div className="selected_users_bottom-role">
                    {listRole.map((name, id, i) => (
                      <p
                        key={id}
                        onClick={() => onClickListRole(id)}
                        className={selectedRole === i ? "active" : ""}
                      >
                        {name}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </div>

            {selectedRoleName !== "Администратор" && (
              <div className="selected">
                <label>Сотрудники</label>
                <div className="selected_box">
                  <div
                    className="selected_users_top"
                    onClick={() => setUsersOpen(!usersOpen)}
                  >
                    {selectedRoleName === "Руководитель"
                      ? `Выбрано сотрудников: ${selectedMetrologUsers.length}`
                      : selectedUsersName || "Выберите Руководителя"}
                    <span
                      className="fas fa-angle-down"
                      style={{ marginLeft: "auto" }}
                    ></span>
                  </div>

                  {usersOpen && (
                    <div className="selected_users_bottom">
                      {users.map((user, id) => {
                        // Проверка роли пользователя для отображения в списке
                        if (
                          (selectedRoleName === "Метролог" &&
                            user.role === "brigadier") ||
                          (selectedRoleName === "Руководитель" &&
                            user.role === "metrologist")
                        ) {
                          return (
                            <p
                              key={id}
                              onClick={() => onClickListUsers(id)}
                              className={
                                selectedRoleName === "Руководитель" &&
                                selectedMetrologUsers.includes(id)
                                  ? "active-users"
                                  : selectedUsers === id
                                  ? "active-users"
                                  : ""
                              }
                            >
                              {user.name}
                            </p>
                          );
                        } else {
                          return null; // Не отображать пользователей с неподходящей ролью
                        }
                      })}
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className="selected">
              <label>Комплект СИ:</label>
              <div className="selected_box">
                <div
                  className="selected_users_top"
                  onClick={() => setPoverkaOpen(!poverkaOpen)}
                >
                  {selectedPoverkaNme}
                  <span className="fas fa-angle-down"></span>
                </div>

                {poverkaOpen && (
                  <div className="selected_users_bottom_cases">
                    {poverka.map((poverka, id) => (
                      <p key={id} onClick={() => onClickListPoverka(id)}>
                        {poverka.name}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="btn_meters">
            <button className="button" onClick={handleCasesClick}>
              <FaSave className="button-icon" />
              <p>Сохранить</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewUsers;
