import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { FaSave } from "react-icons/fa";

const EditMeters = () => {
  const navigate = useNavigate();
  const { meter_id } = useParams();
  const [meterData, setMeterData] = useState(null);
  const accessToken = localStorage.getItem("accessToken");

  const [manufacturerName, setManufacturerName] = useState("");
  const [number, setNumber] = useState("");
  const [nameSI, setNameSI] = useState("");
  const [numberRegistry, setNumberRegistry] = useState("");
  const [mpi, setMpi] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = `https://app.metriva.ru/api/staff/meters/${meter_id}/edit`;
        const headers = {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        };
        const response = await axios.get(apiUrl, { headers });
        setMeterData(response.data.data);

        setNumber(response.data.data.number);
        setNumberRegistry(response.data.data.number_registry);
        setNameSI(response.data.data.name_si);
        setManufacturerName(response.data.data.manufacturer.name);
        setMpi(response.data.data.mpi);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  /*-------- pop-up статус------- */
  const [status, setStatus] = useState("");
  const [statusOpen, SetStatusOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(0);
  const listStatus = ["Верифицированный", "Архивный"];

  const onClickListStatus = (i) => {
    setSelectedStatus(i);
    setStatus(listStatus[i]);
    SetStatusOpen(false);
  };

  const selectedStatusName = status;

  /*------ counter гвс/хвс ---------*/
  const [selectedCold, setSelectedCold] = useState(0);
  const [selectedHot, setSelectedHot] = useState(0);

  const handleIncrement = (type) => {
    if (type === "cold") {
      setSelectedCold((prevValue) => Math.min(Number(prevValue) + 0.5, 99.5));
    } else if (type === "hot") {
      setSelectedHot((prevValue) => Math.min(Number(prevValue) + 0.5, 99.5));
    }
  };

  const handleDecrement = (type) => {
    if (type === "cold") {
      setSelectedCold((prevValue) => Math.max(Number(prevValue) - 0.5, 0));
    } else if (type === "hot") {
      setSelectedHot((prevValue) => Math.max(Number(prevValue) - 0.5, 0));
    }
  };

  useEffect(() => {
    if (meterData) {
      setStatus(
        meterData.status === "verified" ? "Верифицированный" : "Архивный"
      );

      setSelectedCold(meterData.cold);
      setSelectedHot(meterData.hot);
    }
  }, [meterData]);

  /*----функция для отправки данных на бэкэнд---- */
  const handleSave = async () => {
    try {
      const apiUrl = `https://app.metriva.ru/api/staff/meters/${meter_id}`;
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      };

      const data = {
        number_registry: numberRegistry,
        number: number,
        name_si: nameSI,
        manufacturer: {
          name: manufacturerName,
        },
        mpi: mpi,
        status: selectedStatus === 0 ? "verified" : "archive",
        cold: selectedCold,
        hot: selectedHot,
      };

      const response = await axios.put(apiUrl, data, { headers });
      navigate("/admin/meters");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className="add">
        <div className="add_1">
          <h2>Редактировать базу приборов</h2>

          <div className="add__container">
            <div className="selected_meter">
              <label>№ в реестре (ФИФ):</label>
              <input
                value={numberRegistry}
                onChange={(e) => setNumberRegistry(e.target.value)}
              />
            </div>

            <div className="selected_meter">
              <label>№ ФИФ/Аршин:</label>
              <input
                value={number}
                onChange={(e) => setNumber(e.target.value)}
              />
            </div>

            <div className="selected_meter">
              <label>Наименование СИ:</label>
              <input
                value={nameSI}
                onChange={(e) => setNameSI(e.target.value)}
              />
            </div>

            <div className="selected_meter">
              <label>Изготовитель:</label>
              <input
                value={manufacturerName}
                onChange={(e) => setManufacturerName(e.target.value)}
              />
            </div>

            <div className="selected_meter">
              <label>МПИ:</label>
              <input value={mpi} onChange={(e) => setMpi(e.target.value)} />
            </div>

            <div className="selected_meter">
              <label>Статус прибора :</label>

              <div className="protocol_categories-box1">
                <div
                  className="protocol_meters_top"
                  onClick={() => SetStatusOpen(!statusOpen)}
                >
                  {selectedStatusName}
                  <span className="fas fa-angle-down"></span>
                </div>

                {statusOpen && (
                  <div className="protocol_meters_bottom">
                    {listStatus.map((name, i) => (
                      <p key={i} onClick={() => onClickListStatus(i)}>
                        {name}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className="selected_meter">
              <label>Интервал поверки, лет (ХВС):</label>
              <div className="selected_box_counter">
                <button
                  className="btn_selected_box_counter"
                  onClick={() => handleDecrement("cold")}
                >
                  -
                </button>
                <span>{selectedCold}</span>
                <button
                  className="btn_selected_box_counter"
                  onClick={() => handleIncrement("cold")}
                >
                  +
                </button>
              </div>
            </div>

            <div className="selected_meter">
              <label>Интервал поверки, лет (ГВС):</label>
              <div className="selected_box_counter">
                <button
                  className="btn_selected_box_counter"
                  onClick={() => handleDecrement("hot")}
                >
                  -
                </button>
                <span>{selectedHot}</span>
                <button
                  className="btn_selected_box_counter"
                  onClick={() => handleIncrement("hot")}
                >
                  +
                </button>
              </div>
            </div>
          </div>

          <div className="btn_meters">
            <button className="button" onClick={handleSave}>
              <FaSave className="button-icon" />
              <p>Сохранить</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditMeters;
