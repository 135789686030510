import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

const ShowDevice = () => {
  const location = useLocation();
  const { data, apiData } = location.state || {};
  const [protocolData, setProtocolData] = useState(null);
  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    if (data) {
      setProtocolData(data);
    } else {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `https://app.metriva.ru/api/staff/meters/`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
              },
            }
          );
          setProtocolData(response.data);
        } catch (error) {
          console.error(error);
        }
      };

      fetchData();
    }
  }, []);

  const translateStatus = (status) => {
    if (status === "verified") {
      return "Верифицированный";
    } else if (status === "temporary") {
      return "Временный";
    } else if (status === "archive") {
      return "Архивный";
    } else if (status === "imported") {
      return "Импортированный";
    } else {
      return status;
    }
  };

  return (
    <div>
      <div className="main">
        <div className="meter__container">
          <div className="meter_box">
            <div className="meter_title_box">
              <p className="meter_title">Наименование прибора:</p>
              <p className="meter_desc">{protocolData?.manufacturer.name}</p>
            </div>

            <div className="meter_title_box">
              <p className="meter_title">Статус:</p>
              <p className="meter_desc">
                {translateStatus(protocolData?.status)}
              </p>
            </div>

            <div className="meter_title_box">
              <p className="meter_title">Имя в СИ:</p>
              <p className="meter_desc">
                {translateStatus(protocolData?.name_si)}
              </p>
            </div>

            <div className="meter_title_box">
              <p className="meter_title">МПИ:</p>
              <p className="meter_desc">{translateStatus(protocolData?.mpi)}</p>
            </div>

            <div className="meter_title_box">
              <p className="meter_title">Реестровый номер:</p>
              <p className="meter_desc">
                {translateStatus(protocolData?.number_registry)}
              </p>
            </div>

            <div className="meter_title_box">
              <p className="meter_title">Номер ФИФ:</p>
              <p className="meter_desc">{protocolData?.number}</p>
            </div>

            <div className="meter_title_box">
              <div className="meter_water">
                <p className="meter_title-water">Интервал поверкики ХВС:</p>
                <p className="meter_desc-water">{protocolData?.cold}</p>
              </div>
              <div className="meter_water">
                <p className="meter_title-water">Интервал поверкики ГВС:</p>
                <p className="meter_desc-water">{protocolData?.hot}</p>
              </div>
            </div>

            <div className="meter_title_box">
              <p className="meter_title">Дата добавления прибора:</p>
              <p className="meter_desc">{protocolData?.created_at.full}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowDevice;
