import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

const ShowCases = () => {
  const location = useLocation();
  const { data, apiData } = location.state || {};
  const [protocolData, setProtocolData] = useState(null);

  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    // Проверяем, доступны ли уже данные
    if (data) {
      setProtocolData(data);
    } else {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `https://app.metriva.ru/api/staff/cases/`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
              },
            }
          );
          setProtocolData(response.data);
        } catch (error) {
          console.error(error);
        }
      };

      fetchData();
    }
  }, []);

  return (
    <div>
      <div className="main">
        <div className="cases_container">
          <div className="cases_container_tab1">
            <div className="cases_container_title">
              <p>№ Комплекта</p>
            </div>
            <div className="cases_container_desc">
              <p className="cases_container_desc-name">
                {protocolData?.inner_name}
              </p>
            </div>
          </div>
          <div className="cases_container_tab2">
            <div className="cases_container_title">
              <p>Наименование</p>
            </div>
            <div className="cases_container_desc">
              <p className="cases_container_desc-vol">Заводской номер СИ</p>
              <p className="cases_container_desc-vol">
                {protocolData?.kits[0]?.device_type}{" "}
                {protocolData?.kits[0]?.device_sign}
              </p>
              <p className="cases_container_desc-vol">
                {protocolData?.kits[1]?.device_type}{" "}
                {protocolData?.kits[1]?.device_sign}
              </p>
              <p className="cases_container_desc-vol">
                {protocolData?.kits[2]?.device_type}{" "}
                {protocolData?.kits[2]?.device_sign}
              </p>
              <p className="cases_container_desc-vol">
                {protocolData?.kits[3]?.device_type}{" "}
                {protocolData?.kits[3]?.device_sign}
              </p>
              <p className="cases_container_desc-vol">
                {protocolData?.kits[4]?.device_type &&
                protocolData?.kits[4]?.device_sign
                  ? `${protocolData?.kits[4]?.device_type} ${protocolData?.kits[4]?.device_sign}`
                  : "Преобразователь оптоэлектрический «Око» ЛМ ОПТ1.00.03"}
              </p>
              <p className="cases_container_desc-vol">
                Мастер-поверитель АРШИН
              </p>
              <p className="cases_container_desc-vol">СНИЛС</p>
              <p className="cases_container_desc-vol">Мастер-поверитель ФСА</p>
            </div>
          </div>
          <div className="cases_container_tab3">
            <div className="cases_container_title">
              <p>Номер</p>
            </div>
            <div className="cases_container_desc">
              <p className="cases_container_desc-vol">{protocolData?.fif}</p>
              <p className="cases_container_desc-vol">
                {protocolData?.kits[0]?.device_manufacturer_number}
              </p>
              <p className="cases_container_desc-vol">
                {protocolData?.kits[1]?.device_manufacturer_number}
              </p>
              <p className="cases_container_desc-vol">
                {protocolData?.kits[2]?.device_manufacturer_number}
              </p>
              <p className="cases_container_desc-vol">
                {protocolData?.kits[3]?.device_manufacturer_number}
              </p>
              <p className="cases_container_desc-vol">
                {protocolData?.kits[4]?.device_manufacturer_number}
              </p>
              <p className="cases_container_desc-vol">
                {protocolData?.fsa_name}
              </p>
              <p className="cases_container_desc-vol">{protocolData?.snils}</p>
              <p className="cases_container_desc-vol">
                {protocolData?.arshin_name}
              </p>
            </div>
          </div>
          <div className="cases_container_tab4">
            <div className="cases_container_title">
              <p>Описание</p>
            </div>
            <div className="cases_container_desc">
              <p className="cases_container_desc-vol">
                {protocolData?.name}, {protocolData?.reg_name},{" "}
                {protocolData?.link}{" "}
              </p>
              <p className="cases_container_desc-vol">
                {protocolData?.kits[0]?.device_description}
              </p>
              <p className="cases_container_desc-vol">
                {protocolData?.kits[1]?.device_description}
              </p>
              <p className="cases_container_desc-vol">
                {protocolData?.kits[2]?.device_description}
              </p>
              <p className="cases_container_desc-vol">
                {protocolData?.kits[3]?.device_description}
              </p>
              <p className="cases_container_desc-vol">
                {protocolData?.kits[4]?.device_description}
              </p>
              <p className="cases_container_desc-vol"></p>
              <p className="cases_container_desc-vol"></p>
              <p className="cases_container_desc-vol"></p>
            </div>
          </div>
          <div className="cases_container_tab5">
            <div className="cases_container_title">
              <p>Фотография</p>
            </div>
            <div className="cases_container_desc">
              <p className="cases_container_desc-name">Фотография 3х4</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowCases;
