import React from "react";

const LoadingText = () => {
  return (
    <div className="loading-text-container">
      <span className="loading-text">Загружаю данные</span>
      <span className="loading-dot-1">.</span>
      <span className="loading-dot-2">.</span>
      <span className="loading-dot-3">.</span>
    </div>
  );
};

export default LoadingText;
