import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

const Name = ({ onSaveName }) => {
  const accessToken = localStorage.getItem("accessToken");
  const { protocols_id } = useParams();
  const [protocolFormData, setProtocolFormData] = useState({});
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [ownerInitialOptional, setOwnerInitialOptional] = useState("");

  const handleLastNameChange = (event) => {
    const lastNameValue = event.target.value.replace(/\s/g, "");
    const formattedLastName =
      lastNameValue.charAt(0).toUpperCase() + lastNameValue.slice(1);
    setLastName(formattedLastName);
    onSaveName(formattedLastName, firstName, ownerInitialOptional);
  };

  const handleFirstNameChange = (event) => {
    const firstNameValue = event.target.value.replace(/\s/g, "");
    const formattedFirstName =
      firstNameValue.charAt(0).toUpperCase() + firstNameValue.slice(1);
    setFirstName(formattedFirstName);
    onSaveName(lastName, formattedFirstName, ownerInitialOptional);
  };

  const handleOwnerInitialOptional = (event) => {
    const ownerInitialOptionalValue = event.target.value.replace(/\s/g, "");
    const formattedOwnerInitialOptional =
      ownerInitialOptionalValue.charAt(0).toUpperCase() +
      ownerInitialOptionalValue.slice(1);
    setOwnerInitialOptional(formattedOwnerInitialOptional);
    onSaveName(lastName, firstName, formattedOwnerInitialOptional);
  };

  /*-----получаем данные от бэкэнд(первоначальное состояние)---- */
  useEffect(() => {
    const fetchProtocolData = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        };

        const response = await axios.get(
          `https://app.metriva.ru/api/staff/protocols/${protocols_id}/edit`,
          { headers }
        );

        const protocolData = response.data.data;
        setProtocolFormData(protocolData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchProtocolData();
  }, [protocols_id]);

  useEffect(() => {
    if (protocolFormData.owner_name) {
      setLastName(protocolFormData.owner_name);
      onSaveName(
        protocolFormData.owner_name,
        protocolFormData.owner_initial_strict,
        protocolFormData.owner_initial_optional
      );
    }
    if (protocolFormData.owner_initial_strict) {
      setFirstName(protocolFormData.owner_initial_strict);
      onSaveName(
        protocolFormData.owner_name,
        protocolFormData.owner_initial_strict,
        protocolFormData.owner_initial_optional
      );
    }
    if (protocolFormData.owner_initial_optional) {
      setOwnerInitialOptional(protocolFormData.owner_initial_optional);
      onSaveName(
        protocolFormData.owner_name,
        protocolFormData.owner_initial_strict,
        protocolFormData.owner_initial_optional
      );
    }
  }, [protocolFormData]);

  return (
    <div className="name">
      <div className="name__text">
        <p>ФИО заказчика:</p>
      </div>
      <div className="name__box">
        <div className="name__box-name">
          <input
            placeholder="Фамилия"
            value={lastName}
            onChange={handleLastNameChange}
          />
        </div>
        <div className="name__box-name">
          <input
            placeholder="Имя"
            value={firstName}
            onChange={handleFirstNameChange}
          />
        </div>
        <div className="name__box-name">
          <input
            placeholder="Отчество"
            value={ownerInitialOptional}
            onChange={handleOwnerInitialOptional}
          />
        </div>
      </div>
    </div>
  );
};

export default Name;
