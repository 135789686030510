import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { FaSignInAlt } from "react-icons/fa";
import { AiOutlineUser } from "react-icons/ai";

import "../style/loginStyles.css";

const Login = () => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const loggedIn = localStorage.getItem("loggedIn");

    if (loggedIn === "true") {
      navigate("/admin/protocols");
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://app.metriva.ru/api/auth/login",
        {
          login: login,
          password: password,
        }
      );

      if (response.data && response.data.token) {
        const accessToken = response.data.token;
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("loggedIn", "true");

        if (
          (response.data.user && response.data.user.role === "admin") ||
          response.data.user.role === "super"
        ) {
          localStorage.setItem("name", response.data.user.name);
          localStorage.setItem("role", response.data.user.role);

          navigate("/admin/protocols");
        } else {
          setError("У вас нет прав доступа к этой странице");
        }
      } else {
        setError("Неправильно введены данные");
      }
    } catch (error) {
      console.error("Ошибка входа", error);
      console.log(error.response);

      setError("Неверный логин или пароль. Пожалуйста, попробуйте снова.");
    }
  };

  return (
    <div className="wrapper_login">
      <div className="container">
        <div className="container__box">
          <div className="title">
            <div className="user_title">
              Вход
              <AiOutlineUser className="user-icon" />
            </div>
            <p>Введите свои данные</p>
          </div>

          <form className="form" onSubmit={handleSubmit}>
            <div className="form__login">
              <input
                type="text"
                placeholder="Логин"
                value={login}
                onChange={(e) => setLogin(e.target.value)}
              />

              <input
                type="password"
                placeholder="Пароль"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            {error && <div className="error-message">{error}</div>}

            <button className="login-button" type="submit">
              <FaSignInAlt className="login-icon" />
              Войти
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
