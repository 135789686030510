import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  startOfDay,
  addDays,
  addYears,
  isAfter,
  isBefore,
  format,
} from "date-fns";

const DateComponent = ({
  selectedWater,
  onSaveDate,
  selectedRegistryId,
  selectedRegistryHot,
  selectedRegistryCold,
}) => {
  const accessToken = localStorage.getItem("accessToken");
  const { protocols_id } = useParams();
  const [protocolFormData, setProtocolFormData] = useState({});

  const [nextVerificationDate, setNextVerificationDate] = useState(null);
  const [verificationDate, setVerificationDate] = useState(null);
  const [initialVerificationDate, setInitialVerificationDate] = useState(null);
  const [initialNextVerificationDate, setInitialNextVerificationDate] =
    useState(null);

  const [
    initialNextVerificationDateVisible,
    setInitialNextVerificationDateVisible,
  ] = useState(null);

  /*-----получаем данные от бэкэнд(первоначальное состояние)---- */
  useEffect(() => {
    const fetchProtocolData = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        };

        const response = await axios.get(
          `https://app.metriva.ru/api/staff/protocols/${protocols_id}/edit`,
          { headers }
        );

        const protocolData = response.data.data;
        setProtocolFormData(protocolData);

        // Преобразуем строки дат в объекты Date
        const parsedVerificationDate = new Date(protocolData.verification_date);
        setVerificationDate(parsedVerificationDate);

        // Устанавливаем начальное значение nextVerificationDate, если доступно
        if (protocolData.next_verification_date) {
          const parsedNextVerificationDate = new Date(
            protocolData.next_verification_date
          );
          setNextVerificationDate(parsedNextVerificationDate);
          setInitialNextVerificationDate(parsedNextVerificationDate);
          setInitialNextVerificationDateVisible(parsedNextVerificationDate);
        }

        console.log("получаем данные в fetchProtocolData ", protocolData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchProtocolData();
  }, [protocols_id]);

  useEffect(() => {
    // Функция для вычисления следующей даты поверки
    const calculateNextVerificationDate = (date, years) => {
      return addYears(date, years);
    };

    // Определяем значение на сколько лет нужно увеличить дату
    const yearsToAdd =
      selectedWater === "cold" ? selectedRegistryCold : selectedRegistryHot;

    // Проверяем, есть ли значения selectedRegistryHot и selectedRegistryCold
    if (
      typeof selectedRegistryHot !== "undefined" &&
      typeof selectedRegistryCold !== "undefined" &&
      (selectedRegistryHot || selectedRegistryCold) && // Добавьте эту проверку
      verificationDate
    ) {
      // Вычисляем следующую дату поверки
      const nextDate = calculateNextVerificationDate(
        verificationDate,
        yearsToAdd
      );

      // Вычитаем один день из полученной даты
      const previousDay = addDays(nextDate, -1);

      // Форматируем даты
      const formattedVerificationDate = format(verificationDate, "yyyy-MM-dd");
      const formattedNextVerificationDate = format(previousDay, "yyyy-MM-dd");

      onSaveDate(formattedVerificationDate, formattedNextVerificationDate);
      setNextVerificationDate(previousDay);
    } else {
      if (
        initialVerificationDate !== null &&
        initialNextVerificationDate !== null
      ) {
        const formattedInitialVerificationDate = format(
          initialVerificationDate,
          "yyyy-MM-dd"
        );
        const formattedInitialNextVerificationDate = format(
          initialNextVerificationDate,
          "yyyy-MM-dd"
        );

        onSaveDate(
          formattedInitialVerificationDate,
          formattedInitialNextVerificationDate
        );
      }
    }
  }, [
    selectedRegistryHot,
    selectedRegistryCold,
    selectedWater,
    verificationDate,
    onSaveDate,
    initialVerificationDate,
    initialNextVerificationDate,
  ]);

  return (
    <div className="form__footer">
      <div className="form__footer-items">
        <div>
          <p>Дата поверки:</p>
          <div className="calendar">
            <span className="far fa-calendar-alt"></span>
            <DatePicker
              dateFormat="yyyy-MM-dd"
              placeholderText="Выберите дату"
              maxDate={new Date()}
              filterDate={(date) =>
                isAfter(date, addDays(startOfDay(new Date()), -70))
              }
              selected={verificationDate}
              onChange={(date) => setVerificationDate(date)}
            />
          </div>
        </div>
        <div>
          <p>Дата следующей поверки:</p>
          <div className="calendar">
            <span className="far fa-calendar-alt"></span>
            <DatePicker
              className="next_date_protocol"
              dateFormat="yyyy-MM-dd"
              placeholderText=""
              disabled
              selected={nextVerificationDate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateComponent;
