import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

const Examination = ({ onSaveConditions }) => {
  const accessToken = localStorage.getItem("accessToken");
  const { protocols_id } = useParams();
  const [protocolFormData, setProtocolFormData] = useState({});
  const [initialConditions, setInitialConditions] = useState({
    air_temp: "",
    humidity: "",
    pressure: "",
    water_temp: "",
  });

  const [initialConditions1, setInitialConditions1] = useState({
    air_temp: "",
    humidity: "",
    pressure: "",
    water_temp: "",
  });

  /*-----получаем данные от бэкэнд(первоначальное состояние)---- */
  useEffect(() => {
    const fetchProtocolData = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        };

        const response = await axios.get(
          `https://app.metriva.ru/api/staff/protocols/${protocols_id}/edit`,
          { headers }
        );

        const protocolData = response.data.data;
        setProtocolFormData(protocolData);
        setInitialConditions(protocolData.conditions[0]);
        setInitialConditions1(protocolData.conditions[1]);
      } catch (error) {
        console.error(error);
      }
    };

    fetchProtocolData();
  }, [protocols_id]);

  /*----обновляем и передаем значения в компонент Edit----- */
  useEffect(() => {
    onSaveConditions({
      initialConditions,
      initialConditions1,
    });
  }, [initialConditions, initialConditions1]);

  return (
    <div className="examination">
      <div className="examination__text">
        <h2>Условия поверки</h2>
      </div>

      <div className="examination__box">
        <div className="examination__box-left">
          <h4>На начало поверки:</h4>

          <div className="examination__box-items">
            <div className="examination__box-items-text">
              <p>Температура окруж. среды, °C</p>
              <div className="examination__box-items-input">
                <input
                  placeholder=""
                  value={initialConditions.air_temp}
                  onChange={(e) =>
                    setInitialConditions({
                      ...initialConditions,
                      air_temp: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="examination__box-items-text">
              <p>Относительная влажность, %</p>
              <div className="examination__box-items-input">
                <input
                  placeholder=""
                  value={initialConditions1.humidity}
                  onChange={(e) =>
                    setInitialConditions({
                      ...initialConditions,
                      humidity: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="examination__box-items-text">
              <p>Атмосферное давление, кПа</p>
              <div className="examination__box-items-input">
                <input
                  placeholder=""
                  value={initialConditions.pressure}
                  onChange={(e) =>
                    setInitialConditions({
                      ...initialConditions,
                      pressure: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="examination__box-items-text">
              <p>Температура воды, °C</p>
              <div className="examination__box-items-input">
                <input
                  placeholder=""
                  value={initialConditions.water_temp}
                  onChange={(e) =>
                    setInitialConditions({
                      ...initialConditions,
                      water_temp: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div className="examination__box-right">
          <h4>По окончании поверки:</h4>

          <div className="examination__box-items">
            <div className="examination__box-items-text">
              <p>Температура окруж. среды, °C</p>
              <div className="examination__box-items-input">
                <input
                  placeholder=""
                  value={initialConditions1.air_temp}
                  onChange={(e) =>
                    setInitialConditions1({
                      ...initialConditions1,
                      air_temp: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="examination__box-items-text">
              <p>Относительная влажность, %</p>
              <div className="examination__box-items-input">
                <input
                  placeholder=""
                  value={initialConditions1.humidity}
                  onChange={(e) =>
                    setInitialConditions1({
                      ...initialConditions1,
                      humidity: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="examination__box-items-text">
              <p>Атмосферное давление, кПа</p>
              <div className="examination__box-items-input">
                <input
                  placeholder=""
                  value={initialConditions1.pressure}
                  onChange={(e) =>
                    setInitialConditions1({
                      ...initialConditions1,
                      pressure: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="examination__box-items-text">
              <p>Температура воды, °C</p>
              <div className="examination__box-items-input">
                <input
                  placeholder=""
                  value={initialConditions1.water_temp}
                  onChange={(e) =>
                    setInitialConditions1({
                      ...initialConditions1,
                      water_temp: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Examination;
