import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

const Counter = ({
  onMeterFactoryNumber,
  onMeterFactoryYearChange,
  onMeterFactoryWater,
  onSaveRegistrySelection,
}) => {
  const accessToken = localStorage.getItem("accessToken");
  const { protocols_id } = useParams();
  const [protocolFormData, setProtocolFormData] = useState({});

  /*----Получаем данные от бэкэнда для номер в реестр ФИФ----- */
  const [metersData, setMetersData] = useState([]);

  const fetchMeters = async (inputValue) => {
    try {
      const response = await axios.get(
        `https://app.metriva.ru/api/staff/meters?inputValue=${inputValue}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("полученные данные от meters:", response.data);
      setMetersData(response.data.data);
    } catch (error) {
      console.error("Error fetching meters:", error);
    }
  };

  useEffect(() => {
    fetchMeters();
  }, []);

  /*----реестр фиф---- */
  const [numberRegistryOpen, setNumberRegistryOpen] = useState(false);
  const [selectedMeterData, setSelectedMeterData] = useState({
    id: "",
    cold: "",
    hot: "",
    number_registry: "",
  });
  const [inputValue, setInputValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const handleInputChange = (event) => {
    setInputValue(event.target.value);

    setSelectedValue("");
    setSelectedMeterData({
      id: "",
      cold: "",
      hot: "",
      number_registry: "",
    });

    fetchMeters(event.target.value);
  };

  const handleSelectValue = (selectedValue) => {
    setInputValue(selectedValue);
    setSelectedValue(selectedValue);

    const selectedMeter = metersData.find(
      (meter) => meter.number_registry === selectedValue
    );

    if (selectedMeter) {
      const { id, cold, hot, number_registry } = selectedMeter;
      setSelectedMeterData({ id, cold, hot, number_registry });
      onSaveRegistrySelection(id, cold, hot, number_registry);
    }
  };

  useEffect(() => {
    if (selectedValue !== "") {
      setNumberRegistryOpen(false);
    }
  }, [selectedValue]);

  useEffect(() => {
    if (protocolFormData.meter && protocolFormData.meter.number_registry) {
      setInputValue(protocolFormData.meter.number_registry);
    }
  }, [protocolFormData.meter]);

  /*----Наименование счетчика---- */
  const [selectedName, setSelectedName] = useState("");
  useEffect(() => {
    if (protocolFormData.meter && protocolFormData.meter.name_si) {
      setSelectedName(protocolFormData.meter.name_si);
    }
  }, [protocolFormData.meter]);

  /*-----получаем данные от бэкэнд(первоначальное состояние)---- */
  useEffect(() => {
    const fetchProtocolData = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        };

        const response = await axios.get(
          `https://app.metriva.ru/api/staff/protocols/${protocols_id}/edit`,
          { headers }
        );

        const protocolData = response.data.data;
        setProtocolFormData(protocolData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchProtocolData();
  }, [protocols_id]);

  /*--------po-up Год---- */
  const [year, setYear] = useState(false);
  const [selectedYear, setSelectedYear] = useState(0);
  const list3 = Array.from({ length: 24 }, (_, index) =>
    (2000 + index).toString()
  );

  const handleYearChange = (index) => {
    const selectedYear = list3[index];
    setSelectedYear(index);
    setYear(false);
    setProtocolFormData((prevFormData) => ({
      ...prevFormData,
      meter_factory_year: selectedYear,
    }));
    onMeterFactoryYearChange(selectedYear);
  };

  useEffect(() => {
    // Инициализация значения selectedYear на основе данных из бэкэнда
    if (protocolFormData.meter_factory_year) {
      setSelectedYear(list3.indexOf(protocolFormData.meter_factory_year));
    }
    onMeterFactoryYearChange(protocolFormData.meter_factory_year);
  }, [protocolFormData.meter_factory_year]);

  /*-----select Тип Воды--- */
  useEffect(() => {
    if (protocolFormData.meter_type) {
      setSelectedWater(protocolFormData.meter_type);
      onMeterFactoryWater(protocolFormData.meter_type);
    }
  }, [protocolFormData.meter_type]);

  const [selectedWater, setSelectedWater] = useState("cold");
  const handleWaterSelection = (selectedWater) => {
    setSelectedWater(selectedWater);
    onMeterFactoryWater(selectedWater);
  };

  /*------Заводской номер---- */
  const [meterFactoryNumber, setMeterFactoryNumber] = useState("");

  const handleMeterFactoryNumberChange = (event) => {
    const value = event.target.value;
    setMeterFactoryNumber(value); // Устанавливаем актуальное значение
    onMeterFactoryNumber(value); // Передаем актуальное значение
  };

  // Эффект для установки начального значения заводского номера
  useEffect(() => {
    if (protocolFormData.meter_factory_number) {
      setMeterFactoryNumber(protocolFormData.meter_factory_number);
      onMeterFactoryNumber(protocolFormData.meter_factory_number);
    }
  }, [protocolFormData.meter_factory_number]);

  return (
    <div className="protocol__counter">
      <div className="protocol__counter-text">
        <p>Поверяемый счётчик</p>
      </div>
      <div className="protocol__counter-items">
        <div className="protocol__counter-paragraph">
          <div className="protocol__counter-paragraph1">
            <p>Номер в реестре (ФИФ) :</p>
            <div className="protocol__counter-paragraph-items">
              <div
                className="search"
                onClick={() => setNumberRegistryOpen(!numberRegistryOpen)}
              >
                <input value={inputValue} onChange={handleInputChange} />
              </div>
            </div>
          </div>

          {inputValue && metersData.length > 0 && numberRegistryOpen && (
            <div className="fif_down">
              {metersData
                .filter(
                  (meter) =>
                    meter.number_registry &&
                    meter.number_registry.startsWith(inputValue)
                )
                .map((meter) => (
                  <p
                    key={meter.id}
                    onClick={() => handleSelectValue(meter.number_registry)}
                  >
                    {meter.number_registry}
                  </p>
                ))}
            </div>
          )}
        </div>

        <div className="protocol__counter-paragraph">
          <p>Наименование счётчика :</p>
          <div className="protocol__counter-paragraph-items">
            <div className="search">
              {selectedValue
                ? metersData
                    .filter((meter) => meter.number_registry === selectedValue)
                    .map((meter) => <p key={meter.id}>{meter.name_si}</p>)
                : selectedName}
            </div>
          </div>
        </div>

        <div className="protocol__counter-paragraph-year">
          <p>Год выпуска :</p>
          <div className="protocol__counter-paragraph-items">
            <div className="search-year" onClick={() => setYear(!year)}>
              <p>{protocolFormData.meter_factory_year}</p>
              <span className="fas fa-angle-down"></span>
            </div>

            {year && (
              <div className="fif_down">
                {list3.map((name, index) => (
                  <p
                    key={index}
                    onClick={() => handleYearChange(index)}
                    className={selectedYear === index ? "active-1" : ""}
                  >
                    {name}
                  </p>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="protocol__counter-paragraph-water">
          <p>Измеряемая вода:</p>
          <div className="protocol__counter-paragraph-items-water">
            {selectedWater !== "hot" && (
              <div
                className={`search-water-cold ${
                  selectedWater === "hot" ? "selected" : ""
                }`}
                onClick={() => handleWaterSelection("hot")}
              >
                <p>Холодная</p>
              </div>
            )}
            {selectedWater !== "cold" && (
              <div
                className={`search-water-hot ${
                  selectedWater === "cold" ? "selected" : ""
                }`}
                onClick={() => handleWaterSelection("cold")}
              >
                <p>Горячая</p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="protocol__counter-paragraph-items-bottom">
        <p>Заводской номер :</p>
        <input
          className="protocol__counter-paragraph-items-bottom-input"
          placeholder="Напишите заводской номер"
          value={meterFactoryNumber}
          onChange={handleMeterFactoryNumberChange}
        />
      </div>
    </div>
  );
};

export default Counter;
