import React, { useState } from "react";
import axios from "axios";

const Address = ({ onSaveAddress }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState("");
  const [isSuggestionsVisible, setIsSuggestionsVisible] = useState(false);

  const [ownerAddress, setOwnerAddress] = useState("");
  const [typingTimeout, setTypingTimeout] = useState(null);

  const handleAddressChange = (event) => {
    const address = event.target.value;
    setOwnerAddress(address);
    onSaveAddress(address);
    setSelectedSuggestion("");
    setIsSuggestionsVisible(address.length > 0);

    // Очистить предыдущую задержку перед отправкой нового запроса
    clearTimeout(typingTimeout);

    // Установить новую задержку перед отправкой запроса
    const timeout = setTimeout(() => {
      fetchSuggestions(address);
    }, 300); // Например, 500 миллисекунд задержки

    setTypingTimeout(timeout);
  };

  const fetchSuggestions = async (inputValue) => {
    try {
      const response = await axios.post(
        "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address",
        {
          query: inputValue,
          count: 5, // Количество возвращаемых подсказок
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Token 4004411614eac159f915e4ae43989e1565b5ca1b",
          },
        }
      );

      const suggestions = response.data.suggestions.map((item) => item.value);
      setSuggestions(suggestions);
      setIsSuggestionsVisible(suggestions.length > 0);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setSelectedSuggestion(suggestion);
    setOwnerAddress(suggestion);
    onSaveAddress(suggestion);
    setSuggestions([]);
    setIsSuggestionsVisible(false);
  };

  return (
    <div className="address">
      <div className="address__items">
        <p>Адрес проведения поверки:</p>
      </div>
      <div className="address__text">
        <div className="address__input">
          <input
            placeholder="Адрес"
            value={ownerAddress}
            onChange={handleAddressChange}
          />
        </div>
        {ownerAddress.length > 0 && isSuggestionsVisible && (
          <div className="address__suggestions">
            {suggestions.map((suggestion, index) => (
              <p key={index} onClick={() => handleSuggestionClick(suggestion)}>
                {suggestion}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Address;
