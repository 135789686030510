import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";

import Complect from "./AddProtocol/Complect";
import Counter from "./AddProtocol/Counter";
import Name from "./AddProtocol/Name";
import Address from "./AddProtocol/Address";
import BlockTick from "./AddProtocol/BlockTick";
import Testimony from "./AddProtocol/Testimony";
import Examination from "./AddProtocol/Examination";
import Inspection from "./AddProtocol/Inspection";
import DateComponent from "./AddProtocol/DateComponent";

import "../../style/style.scss";

const NewProtocol = () => {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("accessToken");

  /*-----Время в условия поверки------ */
  const currentTime = new Date();
  const year = currentTime.getFullYear();
  const month = String(currentTime.getMonth() + 1).padStart(2, "0");
  const day = String(currentTime.getDate()).padStart(2, "0");
  const hours = String(currentTime.getHours()).padStart(2, "0");
  const minutes = String(currentTime.getMinutes()).padStart(2, "0");
  const seconds = String(currentTime.getSeconds()).padStart(2, "0");

  const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  /*-------Меняем время в conditions */
  const getRandomTime = (currentTime) => {
    const randomOffsetMinutes = Math.floor(Math.random() * 11) + 40; // Генерируем случайное число от 40 до 50 для минут
    const randomOffsetSeconds = Math.floor(Math.random() * 60); // Генерируем случайное число от 0 до 59 для секунд

    const offsetMilliseconds =
      (randomOffsetMinutes * 60 + randomOffsetSeconds) * 1000; // Переводим минуты и секунды в миллисекунды
    const randomTime = new Date(
      currentTime.getTime() - offsetMilliseconds
    ).toLocaleTimeString();
    return randomTime;
  };

  const firstObjectTime = getRandomTime(currentTime);
  const secondObjectTime = currentTime.toLocaleTimeString();

  /*-----Счетчик------ */
  const [meterFactoryNumber, setMeterFactoryNumber] = useState("");
  const [meterFactoryYear, setMeterFactoryYear] = useState("");
  const [selectedWater, setSelectedWater] = useState("cold");
  const [selectedRegistryValue, setSelectedRegistryValue] = useState("");
  const [selectedRegistryId, setSelectedRegistryId] = useState("");

  /*-----Имя/Адрес------ */
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [ownerInitialOptional, setOwnerInitialOptional] = useState("");
  const [ownerAddress, setOwnerAddress] = useState("");

  /*-----Дата поверки/дата следующей поверки------ */
  const [verificationDate, setVerificationDate] = useState("");
  const [nextVerificationDate, setNextVerificationDate] = useState("");

  /*-----Определение относительной погрешности------ */
  const [start, setStart] = useState("");
  const [finish, setFinish] = useState("");
  const [reference, setReference] = useState((0.01).toFixed(3));
  const [volumeDifference, setVolumeDifference] = useState("0.000");
  const [formattedRelativeError, setFormattedRelativeError] = useState("0.00");
  const [blockColor, setBlockColor] = useState("3px solid #ee6969");
  const [start1, setStart1] = useState("");
  const [finish1, setFinish1] = useState("");
  const [reference1, setReference1] = useState((0.02).toFixed(3));
  const [volumeDifference1, setVolumeDifference1] = useState("0.000");
  const [formattedRelativeError1, setFormattedRelativeError1] =
    useState("0.00");
  const [blockColor1, setBlockColor1] = useState("3px solid #ee6969");
  const [start2, setStart2] = useState("");
  const [finish2, setFinish2] = useState("");
  const [consumption, setConsumption] = useState("");
  const [reference2, setReference2] = useState((0.1).toFixed(3));
  const [volumeDifference2, setVolumeDifference2] = useState("0.000");
  const [formattedRelativeError2, setFormattedRelativeError2] =
    useState("0.00");
  const [blockColor2, setBlockColor2] = useState("3px solid #ee6969");

  /*-----Условие поверки------ */
  const [examinationData, setExaminationData] = useState({});

  /*-----Счетчик------ */
  const handleMeterFactoryNumber = (meterFactoryNumber) => {
    setMeterFactoryNumber(meterFactoryNumber);
  };
  const handleMeterFactoryYearChange = (year) => {
    setMeterFactoryYear(year);
  };
  const handleWaterSelection = (waterType) => {
    setSelectedWater(waterType);
  };

  const handleSaveRegistrySelection = (value, id) => {
    setSelectedRegistryValue(value);
    setSelectedRegistryId(id);
  };

  /*-----Определение относительной погрешности------ */
  const handleSaveTestimony = (testimonyData) => {
    const {
      start,
      finish,
      start1,
      finish1,
      start2,
      finish2,
      consumption,
      reference,
      volumeDifference,
      formattedRelativeError,
      blockColor,
      reference1,
      volumeDifference1,
      formattedRelativeError1,
      blockColor1,
      reference2,
      volumeDifference2,
      formattedRelativeError2,
      blockColor2,
    } = testimonyData;

    setStart(start);
    setFinish(finish);
    setStart1(start1);
    setFinish1(finish1);
    setStart2(start2);
    setFinish2(finish2);
    setConsumption(consumption);
    setReference(reference);
    setVolumeDifference(volumeDifference);
    setFormattedRelativeError(formattedRelativeError);
    setBlockColor(blockColor);
    setReference1(reference1);
    setVolumeDifference1(volumeDifference1);
    setFormattedRelativeError1(formattedRelativeError1);
    setBlockColor1(blockColor1);
    setReference2(reference2);
    setVolumeDifference2(volumeDifference2);
    setFormattedRelativeError2(formattedRelativeError2);
    setBlockColor2(blockColor2);
  };

  /*-----Имя/Адрес------ */
  const handleSaveAddress = (address) => {
    setOwnerAddress(address);
  };
  const handleSaveName = (last, first, ownerInitialOptional) => {
    setLastName(last);
    setFirstName(first);
    setOwnerInitialOptional(ownerInitialOptional);
  };

  /*---------Внешний осмотр----- */
  const [isOut, setIsOut] = useState(false);
  const [isTesting, setIsTesting] = useState(false);

  const handleOutShowBox = (isOut) => {
    setIsOut(isOut);
  };

  const handleTesting = (isTesting) => {
    setIsTesting(isTesting);
  };

  /*-----Заключение------ */
  const [areBothChecked, setAreBothChecked] = useState(false);

  useEffect(() => {
    // Проверяем, выполняются ли все необходимые условия
    const areConditionsMet =
      isOut &&
      isTesting &&
      blockColor === "3px solid #2897bf" &&
      blockColor1 === "3px solid #2897bf" &&
      blockColor2 === "3px solid #2897bf";

    // Обновляем значение areBothChecked на основе условий
    setAreBothChecked(areConditionsMet);
  }, [isOut, isTesting, blockColor, blockColor1, blockColor2]);

  /*-----Дата поверки/дата следующей поверки------ */
  const handleSaveDateInNewProtocol = (date, nextDate) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    const formattedNextDate = format(nextDate, "yyyy-MM-dd");

    setVerificationDate(formattedDate);
    setNextVerificationDate(formattedNextDate);
  };

  /*-----Подтвержденные/не подтвержденные--- */
  const [selectedStatus, setSelectedStatus] = useState("ready");
  const [confirm, setConfirm] = useState(false);
  const [selectedConfirm, setSelectedConfirm] = useState(0);
  const listConfirm = ["Не подтвержденный", "Подтвержденный"];
  const onClickListConfirm = (i) => {
    setSelectedConfirm(i);
    setConfirm(false);

    if (i === 0) {
      setSelectedStatus("ready");
    } else if (i === 1) {
      setSelectedStatus("checked");
    }
  };

  const SelectedConfirmName = listConfirm[selectedConfirm];

  const handleSaveProtocol = () => {
    const apiUrl = "https://app.metriva.ru/api/staff/protocols";
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    };

    const data = {
      meter_factory_number: meterFactoryNumber,
      meter_factory_year: meterFactoryYear,
      meter_type: selectedWater,

      owner_name: lastName,
      owner_initial_strict: firstName,
      owner_initial_optional: ownerInitialOptional,
      owner_address: ownerAddress,

      verification_date: verificationDate,
      next_verification_date: nextVerificationDate,

      accepted_at: formattedDateTime,
      meter_id: selectedRegistryId,
      case_id: 1,
      status: "checked",
      mechanical_accepted: isOut,

      tightness_accepted: isTesting,
      total_accepted: areBothChecked,

      conditions: [
        {
          id: 1,
          air_temp: parseFloat(examinationData.air_temp),
          water_temp: parseFloat(examinationData.water_temp1),
          humidity: parseFloat(examinationData.humidity),
          pressure: parseFloat(examinationData.pressure),
          time: firstObjectTime,
        },

        {
          id: 2,
          air_temp: parseFloat(examinationData.air_temp),
          water_temp: parseFloat(examinationData.water_temp2),
          humidity: parseFloat(examinationData.humidity),
          pressure: parseFloat(examinationData.pressure),
          time: secondObjectTime,
        },
      ],

      indications: [
        {
          id: 1,
          number: 5,
          indication_number: parseFloat(volumeDifference),
          permissible_error: parseFloat(formattedRelativeError),
          consumption: 0.3,
          start: parseFloat(start),
          finish: parseFloat(finish),
          reference: parseFloat(reference),
        },
        {
          id: 2,
          number: 2,
          indication_number: parseFloat(volumeDifference1),
          permissible_error: parseFloat(formattedRelativeError1),
          consumption: 0.132,
          start: parseFloat(start1),
          finish: parseFloat(finish1),
          reference: parseFloat(reference1),
        },
        {
          id: 3,
          number: 2,
          indication_number: parseFloat(volumeDifference2),
          permissible_error: parseFloat(formattedRelativeError2),
          consumption: parseFloat(consumption),
          start: parseFloat(start2),
          finish: parseFloat(finish2),
          reference: parseFloat(reference2),
        },
      ],
    };

    axios
      .post(apiUrl, data, { headers })

      .then((response) => {
        console.log("Данные успешно отправлены на сервер");
        navigate("/admin/protocols");
      })
      .catch((error) => {
        if (error.response) {
          console.error(
            "Ошибка при отправке данных на сервер:",
            error.response.data
          );
        } else {
          console.error("Ошибка при отправке данных на сервер:", error.message);
        }
      });
  };

  return (
    <main className="main-container">
      <div className="protocol__form">
        <Counter
          setSelectedWater={setSelectedWater}
          selectedWater={selectedWater}
          onMeterFactoryNumber={handleMeterFactoryNumber}
          onMeterFactoryYearChange={handleMeterFactoryYearChange}
          onMeterFactoryWater={handleWaterSelection}
          onSaveRegistrySelection={handleSaveRegistrySelection}
          selectedRegistryId={selectedRegistryId}
        />

        <Name onSaveName={handleSaveName} />
        <Address onSaveAddress={handleSaveAddress} />
        <BlockTick
          onOutShowBox={handleOutShowBox}
          onTestingBox={handleTesting}
        />
        <Testimony onSaveTestimony={handleSaveTestimony} />
        <Examination
          setExaminationData={setExaminationData}
          selectedWater={selectedWater}
        />

        <Inspection areBothChecked={areBothChecked} />
        <DateComponent
          selectedWater={selectedWater}
          onSaveDateInNewProtocol={handleSaveDateInNewProtocol}
          selectedRegistryId={selectedRegistryId}
        />
        <button className="btn_main" onClick={handleSaveProtocol}>
          Сохранить
        </button>
      </div>
    </main>
  );
};

export default NewProtocol;
