import React, { useEffect, useState } from "react";

const Examination = ({ selectedWater, setExaminationData }) => {
  const [air_temp, setAirTemp] = useState("");
  const [humidity, setHumidity] = useState("");
  const [pressure, setPressure] = useState("");
  const [waterTemp, setWaterTemp] = useState("");

  const [temperatureDifference, setTemperatureDifference] = useState(0);

  const getRandomNumber = (min, max, decimalPlaces) => {
    const randomNumber = Math.random() * (max - min) + min;
    return randomNumber.toFixed(decimalPlaces);
  };

  const air_tempMin = 23;
  const air_tempMax = 25;
  const humidityMin = 43;
  const humidityMax = 45;
  const pressureMin = 90;
  const pressureMax = 98;

  useEffect(() => {
    setAirTemp(getRandomNumber(air_tempMin, air_tempMax, 1));
    setHumidity(getRandomNumber(humidityMin, humidityMax, 1));
    setPressure(getRandomNumber(pressureMin, pressureMax, 0));
  }, []);

  useEffect(() => {
    if (selectedWater === "hot") {
      const randomTemp = getRandomTemperature(30, 50);
      setWaterTemp(randomTemp.toString());

      // Вычисляем разницу в температуре для горячей воды
      const difference = getRandomTemperature(5, 10);
      setTemperatureDifference(-difference);
    } else if (selectedWater === "cold") {
      const randomTemp = getRandomTemperature(5, 25);
      setWaterTemp(randomTemp.toString());

      // Вычисляем разницу в температуре для холодной воды
      const difference = getRandomTemperature(5, 10);
      setTemperatureDifference(difference);
    }
  }, [selectedWater]);

  const getRandomTemperature = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  useEffect(() => {
    setExaminationData((prevData) => ({
      ...prevData,
      air_temp,
      humidity,
      pressure,
      water_temp1: waterTemp,
      water_temp2: parseInt(waterTemp) + temperatureDifference,
    }));
  }, [waterTemp, setExaminationData]);

  return (
    <div className="examination">
      <div className="examination__text">
        <h2>Условия поверки</h2>
      </div>

      <div className="examination__box">
        <div className="examination__box-left">
          <h4>На начало поверки:</h4>

          <div className="examination__box-items">
            <div className="examination__box-items-text">
              <p>Температура окружающей среды, °C</p>
              <div className="examination__box-items-input">
                <input
                  placeholder=""
                  value={air_temp}
                  onChange={(e) => setAirTemp(e.target.value)}
                />
              </div>
            </div>

            <div className="examination__box-items-text">
              <p>Относительная влажность, %</p>
              <div className="examination__box-items-input">
                <input
                  placeholder=""
                  value={humidity}
                  onChange={(e) => setHumidity(e.target.value)}
                />
              </div>
            </div>

            <div className="examination__box-items-text">
              <p>Атмосферное давление, кПа</p>
              <div className="examination__box-items-input">
                <input
                  placeholder=""
                  value={pressure}
                  onChange={(e) => setPressure(e.target.value)}
                />
              </div>
            </div>

            <div className="examination__box-items-text">
              <p>Температура воды, °C</p>
              <div className="examination__box-items-input">
                <input
                  placeholder=""
                  value={waterTemp.toString()}
                  onChange={(e) => setWaterTemp(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="examination__box-right">
          <h4>По окончании поверки:</h4>

          <div className="examination__box-items">
            <div className="examination__box-items-text">
              <p>Температура окружающей среды, °C</p>
              <div className="examination__box-items-input">
                <input
                  placeholder=""
                  value={air_temp}
                  onChange={(e) => setAirTemp(e.target.value)}
                />
              </div>
            </div>

            <div className="examination__box-items-text">
              <p>Относительная влажность, %</p>
              <div className="examination__box-items-input">
                <input
                  placeholder=""
                  value={humidity}
                  onChange={(e) => setHumidity(e.target.value)}
                />
              </div>
            </div>

            <div className="examination__box-items-text">
              <p>Атмосферное давление, кПа</p>
              <div className="examination__box-items-input">
                <input
                  placeholder=""
                  value={pressure}
                  onChange={(e) => setPressure(e.target.value)}
                />
              </div>
            </div>

            <div className="examination__box-items-text">
              <p>Температура воды, °C</p>
              <div className="examination__box-items-input">
                <input
                  placeholder=""
                  value={(
                    parseInt(waterTemp) + temperatureDifference
                  ).toString()}
                  onChange={(e) => setWaterTemp(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Examination;
