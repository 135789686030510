import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaFile, FaUser, FaDatabase, FaToolbox } from "react-icons/fa";
import { AiOutlineLogout } from "react-icons/ai";
import Logo from "../assets/logo-sm.png";

const HomeLeft = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const name = localStorage.getItem("name");
    const role = localStorage.getItem("role");

    if (name && role) {
      setUserData({ name, role });
    } else {
      navigate("/");
    }
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem("name");
    localStorage.removeItem("role");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("loggedIn");
    localStorage.removeItem("selectedCompanyId");
    localStorage.removeItem("selectedUser");

    setUserData(null);
    navigate("/");
  };

  const sections = [
    { id: "protocols", label: "Протоколы", icon: <FaFile /> },
    { id: "users", label: "Пользователи", icon: <FaUser /> },
    { id: "meters", label: "База приборов", icon: <FaDatabase /> },
    { id: "cases", label: "Средства измерений", icon: <FaToolbox /> },
  ];

  const formatLabel = (label) => {
    if (label === "admin") {
      return "Администратор";
    }
    if (label === "super") {
      return "Супер - администратор";
    } else {
      return label.charAt(0).toUpperCase() + label.slice(1).toLowerCase();
    }
  };

  return (
    <div>
      <div className="container__left">
        <div className="header__left">
          <img className="logo" src={Logo} width={150} height={30} alt="Logo" />
          <div className="header__title">
            {sections.map((section) => (
              <h2
                key={section.id}
                className={
                  location.pathname.includes(section.id) ? "active" : ""
                }
                onClick={() => navigate(`/admin/${section.id}`)}
              >
                <ul className="no-bullet">
                  <li>
                    <span style={{ marginRight: "8px" }}>{section.icon}</span>
                    {formatLabel(section.label)}
                  </li>
                </ul>
              </h2>
            ))}
          </div>
        </div>
        <div className="home__footer-left">
          <div className="home__footer-left-title">
            <h2>
              {userData
                ? `${userData.name}: ${formatLabel(userData.role)}`
                : "Загрузка..."}
            </h2>
          </div>
          <div className="home__footer_left_exit" onClick={handleLogout}>
            Выход
            <AiOutlineLogout className="logout" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeLeft;
